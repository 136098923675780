import { ActionTypes } from 'actions/types';

export const initialUserState = {
    sub:       '',
    userEmail: '',
    userName:  ''
};

const userReducer = (state = initialUserState, action: ActionTypes) => {

  switch (action.type) {


    case 'SET_USER_STATE':
    {
       return {
         ...action.payload 
       }
    }

    

    default: return state;
  }
};


export { userReducer };
