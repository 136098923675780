import React          from 'react';
import resetStates    from 'functions/reset_states';
import { colors }     from 'utils/colors';
import { makeStyles } from '@material-ui/core/styles';

const success = "Data seccessfuly uploaded"

const useStyles = makeStyles(() => ({
    root: {
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
        flexFlow:        'column',
        backgroundColor: '#FFF'
    },
    title: {
       display:         'flex',
       alignItems:      'center',
       justifyContent:  'center',
       cursor:          'default',
       height:          40,
       fontSize:        18,
    },
    button: {
       width:           200,
       height:          45,
       margin:          20,
       display:         'flex',
       alignItems:      'center',
       justifyContent:  'center',
       color:           '#FFF',
       backgroundColor: colors.primary,
       fontWeight:      'bold',
       cursor:          'pointer',
       '&:hover': {
          opacity: 0.9
       }
    }
}))

/* 
type SuccessPropsType = {
    reset: (form: boolean) => void;
}
 */
const SuccessPage: React.FC = () => {

    const classes = useStyles();

    return(
        <div className = { classes.root }>
            <div className = { classes.title }>
                { success }
            </div>
            <div
               className = { classes.button }
               onClick   = { () => { resetStates('', 'free access') }}
            >
                ok
            </div>
        </div>
    )
}

export default SuccessPage;