import { ActionTypes } from 'actions/types';

export const initialDuplicationState = {
    duplicateMode: false,
    selectedItems: [] as number[]
};

const duplicationReducer = (state = initialDuplicationState, action: ActionTypes) => {

  switch (action.type) {


    case 'SET_DUPLICATION_STATE':
    {

       return {
         ...action.payload 
       }
    }

    default: return state;
  }
};


export { duplicationReducer };
