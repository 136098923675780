
export const UNSUBSCRIBE_SAGA = () => ({
    type: 'UNSUBSCRIBE_SAGA',
    payload: true
  });


export const REACTIVATE_SUBSCRIPTION_SAGA = () => ({
  type: 'REACTIVATE_SUBSCRIPTION_SAGA',
  payload: true
});

