import { call, take, put } from 'redux-saga/effects';
import axios               from 'axios';
import {
  SUBMITION_INIT,
  SUBMITION_COMPLETED,
  SHOW_ERROR,
  SET_QUESTIONS
}                          from '../actions';

const endpoint = 'https://iseemath.co/adminApi/editQuestion.php';

const updateQuestionArr = (arr: {[key: string]: any;}[], newQuestion: {[key: string]: any;}) => {

    for ( let i = 0; i < arr.length; i++ ) {
      if ( arr[i].id === newQuestion.id ) {
        arr[i] = newQuestion;
        break;
      }
    }
    return arr;
}

function* editQuestion(): Generator<any,any,any> {
  while(true) {
    try {

        const userInput = yield take('EDIT_QUESTION_SAGA');

        console.log(userInput)

        yield put(SUBMITION_INIT());

        const { sub, questionArr, questionsState } = userInput.payload;

        if (process.env.NODE_ENV === 'development') {


           const updatedQuestionArr = yield(updateQuestionArr(questionArr, questionsState))
           yield put(SET_QUESTIONS(updatedQuestionArr));
           yield put(SUBMITION_COMPLETED(false));

           
        }

        if (process.env.NODE_ENV === 'production') {

            const obj = {
              ...questionsState,
              sub: sub
            }

            const res = yield call( axios.post, endpoint, obj );

            console.log('res       :  ',res);
            console.log('res.data  :  ',res.data);
                   

            if ( !!res && !!res.data && !!res.data.success && res.data.success === 1 ) {
               const updatedQuestionArr = yield(updateQuestionArr(questionArr, questionsState))
               yield put(SET_QUESTIONS(updatedQuestionArr));
               yield put(SUBMITION_COMPLETED(false));
            }
            else {
              yield put(SHOW_ERROR());
            }
        }
    }
    catch(err) {
      yield put(SHOW_ERROR());
      console.log('err  :  '+err);

    }
    finally {}
  }
}


export default editQuestion;
