import React                   from 'react';
import { connect }             from 'react-redux';
import { Dispatch }            from "redux";
import { reducerType }         from 'reducer';
import { ActionTypes }         from 'actions/types';
import resetStates             from 'functions/reset_states';
import submitTopicIntroForm    from 'functions/forms/submit_topic_intro';
import { topicIntroStateType } from 'types';
import { colors }              from 'utils/colors';
import { makeStyles }          from '@material-ui/core/styles';
import { SET_TOPIC_INTRO_STATE } from 'actions/topicIntroActions';

const backendErr = "Data not uploaded dut to error";

const useStyles = makeStyles(() => ({
    root: {
        position:        'fixed',
        top:             0,
        height:          '100vh',
        width:           '100vw',
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
        backgroundColor: '#00000077'
    },
    form: {
        display:         'flex',
        flexFlow:        'column',
        border:          '1px solid #DDD',
        backgroundColor: '#FFF',
        padding:         10
    },
    error: {
       display:         'flex',
       alignItems:      'center',
       justifyContent:  'center',
       cursor:          'default',
       height:          40,
       fontSize:        18,
       color:           '#E00'
    },
    input: {
        width:     '45vw',
        height:    40,
        minWidth:  350,
        fontSize:  18,
        margin:    8
    },
    button: {
       width:           200,
       height:          45,
       margin:          8,
       display:         'flex',
       alignItems:      'center',
       justifyContent:  'center',
       color:           '#FFF',
       backgroundColor: colors.primary,
       fontWeight:      'bold',
       cursor:          'pointer',
       '&:hover': {
          opacity: 0.9
       }
    },
    xContainer: {
        height:          30,
        width:           '100%',
        display:         'flex',
        justifyContent:  'right',
    },
    x: {
        height:          30,
        width:           30,
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
        backgroundColor: colors.primary,
        color:           '#FFF',
        fontWeight:      'bold',
        cursor:          'pointer'

    }
}))


type TopicIntroFormPropsType = {
    topicIntroState:    topicIntroStateType;
    error:              string;
    showError:          boolean;
    setTopicIntroState: ( val: topicIntroStateType ) => void;
}

const TopicIntroForm: React.FC<TopicIntroFormPropsType> = ({ 
    topicIntroState, 
    error, 
    showError, 
    setTopicIntroState 
}) => {

    const classes = useStyles();
    

    const changeInputValue = (e: React.FormEvent) => {

        e.preventDefault();
    
        const target = e.target as HTMLInputElement;

        if (target.id === 'topic')        { setTopicIntroState({ ...topicIntroState, topic:        target.value }) }
        if (target.id === 'sub_topic')    { setTopicIntroState({ ...topicIntroState, sub_topic:    target.value }) }
        if (target.id === 'youtube')      { setTopicIntroState({ ...topicIntroState, youtube:      target.value }) }
        if (target.id === 'img')          { setTopicIntroState({ ...topicIntroState, img:          target.value }) }
        if (target.id === 'description')  { setTopicIntroState({ ...topicIntroState, description:  target.value }) }
        if (target.id === 'teacher_text') { setTopicIntroState({ ...topicIntroState, teacher_text: target.value }) }
        if (target.id === 'student_text') { setTopicIntroState({ ...topicIntroState, student_text: target.value }) }
    
    }


    return(
    <div className = { classes.root }>
        <div className = { classes.form }>
            <div className = { classes.xContainer }>
                <div 
                  className = { classes.x }
                  onClick   = { () => { resetStates('') }}
                >
                    x
                </div>
            </div>
            <div className = { classes.error }>
                { showError ? backendErr : error }
            </div>
            {
                Object.keys(topicIntroState).map((val) => {
                    if ( val === 'id' ) {
                        return(<div key = { '@' }/>)
                    }
                    return(
                        <textarea 
                           key         = { val }
                           className   = { classes.input }
                           id          = { val }
                           placeholder = { val }
                           value       = { topicIntroState[`${ val }`] }
                           onChange    = { changeInputValue }
                           style       = {{
                               height : val === 'question' ? 80 : undefined
                           }}
                        />
                    )
                })
            }
            <div
               className = { classes.button }
               onClick   = { submitTopicIntroForm }
            >
                submit
            </div>
        </div>
    </div>
    )
}

const mapStateToProps = (reducer: reducerType) => {
   return {
       topicIntroState: reducer.topicIntros,
       error:           reducer.components.error,
       showError:       reducer.components.showError
   }
 };

 const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
    setTopicIntroState: (payload: topicIntroStateType) => dispatch(SET_TOPIC_INTRO_STATE(payload)),
});

 export default connect(mapStateToProps, mapDispatchToProps)(TopicIntroForm);
