import { store }                  from 'index'
import { RESET }                  from 'actions';
import { SET_FREE_ACCESS_STATE }  from 'actions/freeAccessActions';
import { initialFreeAccessState } from 'reducer/freeAccessReducer';
import { SET_QUESTIONS_STATE }    from 'actions/questionsActions';
import { initialQuestionsState }  from 'reducer/questionsReducer';
import { SET_TOPIC_INTRO_STATE }  from 'actions/topicIntroActions';
import { initialTopicIntroState } from 'reducer/topicIntroReducer';
import { SET_PUZZLE_STATE }       from 'actions/puzzleActions';
import { initialPuzzleState }     from 'reducer/puzzleReducer';
import { SET_MODAL_STATE }        from 'actions/modalActions';
import { initialModalState }      from 'reducer/modalReducer';
import { SET_DELETE_STATE }       from 'actions/deleteActions';
import { SET_COMPONENTS_STATE }   from 'actions/componentActions';
import { initialDeleteState }     from 'reducer/deleteReducer';

const resetStates = (
    form?: string,
    page?: string
) => {

    const state           = store.getState();
    const componentsState = state.components;

    const newCompState = {
        ...componentsState,
        form:           !!form ? form === 'current' ? componentsState.form : form : '',
        page:           !!page ? page : componentsState.page,
        error:          '',
        actionComplete: false
    }

    store.dispatch(SET_FREE_ACCESS_STATE(initialFreeAccessState));
    store.dispatch(SET_QUESTIONS_STATE(initialQuestionsState));
    store.dispatch(SET_TOPIC_INTRO_STATE(initialTopicIntroState));
    store.dispatch(SET_PUZZLE_STATE(initialPuzzleState));
    store.dispatch(SET_MODAL_STATE(initialModalState));
    store.dispatch(SET_DELETE_STATE(initialDeleteState));
    store.dispatch(SET_COMPONENTS_STATE(newCompState))
    store.dispatch(RESET());
}

export default resetStates;