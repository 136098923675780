import { store }                   from 'index'
import { deleteTopicIntroType }    from "actions/types";
import { DELETE_TOPIC_INTRO_SAGA } from 'actions';

const deleteTopicInrtos = () => {

    const state                 = store.getState();
    const deleteState           = state.delete;
    const topicArr              = state.content.topicIntros;
    const sub                   = state.user.sub;
    const deleteTopicIntrosSaga = (payload: deleteTopicIntroType) => store.dispatch(DELETE_TOPIC_INTRO_SAGA(payload));

    deleteTopicIntrosSaga({ sub: sub, selectedItems: deleteState.selectedItems, topicArr: topicArr })
 }

 export default deleteTopicInrtos;


