import React                      from 'react';
import { connect }                from 'react-redux';
import { Dispatch }               from "redux";
import { reducerType }            from 'reducer';
import { ActionTypes }            from 'actions/types';
import { freeAccessStateType }    from 'types';
import { colors }                 from 'utils/colors';
import { makeStyles }             from '@material-ui/core/styles';
import submitFreeUser             from 'functions/forms/submit_free_user'
import { SET_FREE_ACCESS_STATE }  from 'actions/freeAccessActions';
import { componentsStateType }    from 'reducer/types';
import { initialFreeAccessState } from 'reducer/freeAccessReducer';

const title      = "Enter an email or multiple emails seperated by commas";
const title2     = "Comments";
const backendErr = "Data not uploaded dut to error";

const useStyles = makeStyles(() => ({
    root: {
        display:         'flex',
       // alignItems:      'center',
        justifyContent:  'center',
        flexFlow:        'column',
        backgroundColor: '#FFF'
    },
    title: {
       display:         'flex',
       alignItems:      'center',
      // justifyContent:  'center',
       cursor:          'default',
       height:          40,
       fontSize:        18,
       marginBottom:    20,
    },
    error: {
       display:         'flex',
       alignItems:      'center',
       justifyContent:  'center',
       cursor:          'default',
       height:          40,
       fontSize:        18,
       color:           '#E00'
    },
    input: {
        width:     '55vw',
        height:    '10vh',
        minWidth:  350,
        minHeight: 100,
        fontSize:  18,
    },
    button: {
       width:           200,
       height:          45,
       marginTop:       20,
       display:         'flex',
       alignItems:      'center',
       justifyContent:  'center',
       color:           '#FFF',
       backgroundColor: colors.primary,
       fontWeight:      'bold',
       cursor:          'pointer',
       '&:hover': {
          opacity: 0.9
       }
    },
    
}))


type FreeUserFormPropsType = {
    componentsState:    componentsStateType;
    freeAccessState:    freeAccessStateType;
    setFreeAccessState: ( val: freeAccessStateType ) => void;
}

const FreeUserForm: React.FC<FreeUserFormPropsType> = ({ componentsState, freeAccessState, setFreeAccessState }) => {

    const classes = useStyles();


    React.useEffect(() => {
        if ( componentsState.actionComplete || componentsState.showError ) {
            setFreeAccessState(initialFreeAccessState);
        }
    },[componentsState, setFreeAccessState])
 
    

    const changeInputValue = (e: React.FormEvent) => {

        e.preventDefault();
    
        const target = e.target as HTMLInputElement;
    
        if (target.id === 'emails') { setFreeAccessState({ ...freeAccessState, emails: target.value }) }
        if (target.id === 'comments') { setFreeAccessState({ ...freeAccessState, comments: target.value }) }
    
    }

    return(
        <div className = { classes.root }>
            <div className = { classes.error }>
                { componentsState.showError ? backendErr : componentsState.error }
            </div>
            <div className = { classes.title }>
                   { title }
            </div>
            <textarea 
               className   = { classes.input }
               id          = { 'emails' }
               placeholder = { 'email' }
               value       = { freeAccessState.emails }
               onChange    = { changeInputValue }
            />
            <div className = { classes.title}>
                   { title2 }
            </div>
            <textarea 
               className   = { classes.input }
               id          = { 'comments' }
               placeholder = { 'comments' }
               value       = { freeAccessState.comments }
               onChange    = { changeInputValue }
            />
            <div
               className = { classes.button }
               onClick   = { submitFreeUser }
            >
                submit
            </div>
        </div>
    )
}


const mapStateToProps = (reducer: reducerType) => {
    return {
        freeAccessState: reducer.freeAccess,
        componentsState: reducer.components
    }
  };

  const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
    setFreeAccessState: (payload: freeAccessStateType) => dispatch(SET_FREE_ACCESS_STATE(payload)),
});

  export default connect(mapStateToProps, mapDispatchToProps)(FreeUserForm);
