import { call, take, put } from 'redux-saga/effects';
import axios               from 'axios';
import {
  SUBMITION_INIT,
  SUBMITION_COMPLETED,
  GET_QUESTIONS_SAGA,
  SHOW_ERROR,
}                          from '../actions';

const endpoint = 'https://iseemath.co/adminApi/createQuestion.php';

function* submitQuestion(): Generator<any,any,any> {
  while(true) {
    try {

        const userInput = yield take('SUBMIT_QUESTION_SAGA');

        yield put(SUBMITION_INIT());

        const { newQuestion, sub } = userInput.payload;

        if (process.env.NODE_ENV === 'development') {

          console.log('newQuestion : ',newQuestion)
           yield put(SUBMITION_COMPLETED(false));
         //  yield put(UPDATE_QUESTIONS(newQuestion))
        }

        if (process.env.NODE_ENV === 'production') {

            const obj = {
              ...newQuestion,
              sub: sub
            }

            const res = yield call( axios.post, endpoint, obj );

            console.log('res       :  ',res);
            console.log('res.data  :  ',res.data);
                   

            if ( !!res && !!res.data && !!res.data.success && res.data.success === 1 ) {
              yield put(GET_QUESTIONS_SAGA())
              yield put(SUBMITION_COMPLETED(false));
            }
            else {
              yield put(SHOW_ERROR());
            }
        }
    }
    catch(err) {
      yield put(SHOW_ERROR());
      console.log('err  :  '+err);

    }
    finally {}
  }
}


export default submitQuestion;
