import { store }              from 'index'
import { editquestion_type }  from "actions/types";
import { EDIT_QUESTION_SAGA } from 'actions';

const editQuestion = () => {

    const state          = store.getState();
    const questionsState = state.questions;
    const questionsArr   = state.content.questions;
    const sub            = state.user.sub;
    const editQuestion   = (payload: editquestion_type) => store.dispatch(EDIT_QUESTION_SAGA(payload));

    editQuestion({ sub: sub, questionsState: questionsState, questionArr: questionsArr });
 }

 export default editQuestion;


