import { call, all }          from 'redux-saga/effects';
import getQuestion            from './get_questions';
import getTopicIntros         from './get_topic_intros';
import getPuzzles             from './get_puzzles';
import submitQuestion         from './submit_question';
import submitTopicIntro       from './submit_topic_intro';
import submitPuzzle           from './submit_puzzle';
import editQuestion           from './edit_question';
import editTopicIntro         from './edit_topic_intro';
import editPuzzles            from './edit_puzzles';
import deleteQuestions        from './delete_questions';
import deleteTopicIntro       from './delete_topic_intro';
import deletePuzzles          from './delete_puzzles';
import submitFreeUser         from './submit_free_user';
import uploadData             from './upload_data';
import unsubscribe            from './subscriptions/unsubscribe';
import reactivateSubscription from './subscriptions/reactivate_subscription';
import test                   from './test';


export default function* indexSaga(){
  yield all([
    call(getQuestion),
    call(getTopicIntros),
    call(getPuzzles),
    call(submitFreeUser),
    call(submitQuestion),
    call(submitTopicIntro),
    call(submitPuzzle),
    call(editQuestion),
    call(deleteQuestions),
    call(deletePuzzles),
    call(editTopicIntro),
    call(editPuzzles),
    call(deleteTopicIntro),
    call(uploadData),
    call(unsubscribe),
    call(reactivateSubscription),
    call(test)
  ])
}