
import { 
  submitFreeUserFormType,
  submitQuestionFormType,
  submitPuzzleFormType,
  submitTopicIntroType,
  editquestion_type,
  deletequestion_type,
  editTopicIntroType,
  deleteTopicIntroType,
  deletePuzzlestype,
  editPuzzlesType
} from "./types";
  



// GET




export const GET_QUESTIONS_SAGA = () => ({
  type: 'GET_QUESTIONS_SAGA',
  payload: true
});

export const GET_TOPIC_INTROS_SAGA = () => ({
  type: 'GET_TOPIC_INTROS_SAGA',
  payload: true
});

export const GET_PUZZLES_SAGA = () => ({
  type: 'GET_PUZZLES_SAGA',
  payload: true
});





// SET




export const SET_QUESTIONS = (payload: { [key: string]: any }[]) => ({
  type: 'SET_QUESTIONS',
  payload: payload
});

export const SET_PUZZLES = (payload: { [key: string]: any }[]) => ({
  type: 'SET_PUZZLES',
  payload: payload
});


export const SET_TOPIC_INTROS = (payload: { [key: string]: any }[]) => ({
  type: 'SET_TOPIC_INTROS',
  payload: payload
});





// SUBMIT




export const SUBMIT_FREE_USER_FORM_SAGA = (payload: submitFreeUserFormType) => ({
  type: 'SUBMIT_FREE_USER_FORM_SAGA',
  payload: payload
});

export const SUBMIT_QUESTION_SAGA = (payload: submitQuestionFormType) => ({
  type: 'SUBMIT_QUESTION_SAGA',
  payload: payload
});


export const SUBMIT_PUZZLE_SAGA = (payload: submitPuzzleFormType) => ({
  type: 'SUBMIT_PUZZLE_SAGA',
  payload: payload
});



export const SUBMIT_TOPIC_INTRO_SAGA = (payload: submitTopicIntroType) => ({
  type: 'SUBMIT_TOPIC_INTRO_SAGA',
  payload: payload
});

export const SUBMITION_INIT = () => ({
  type: 'SUBMITION_INIT',
  payload: true
});

export const SUBMITION_COMPLETED = (payload: boolean) => ({
  type: 'SUBMITION_COMPLETED',
  payload: payload
});




// EDIT




export const EDIT_QUESTION_SAGA = (payload: editquestion_type) => ({
  type: 'EDIT_QUESTION_SAGA',
  payload: payload
});

export const EDIT_TOPIC_INTRO_SAGA = (payload: editTopicIntroType) => ({
  type: 'EDIT_TOPIC_INTRO_SAGA',
  payload: payload
});


export const EDIT_PUZZLES_SAGA = (payload: editPuzzlesType) => ({
  type: 'EDIT_PUZZLES_SAGA',
  payload: payload
});






// DELETE



export const DELETE_QUESTIONS_SAGA = ( payload: deletequestion_type ) => ({
  type: 'DELETE_QUESTIONS_SAGA',
  payload: payload
});



export const DELETE_TOPIC_INTRO_SAGA = ( payload: deleteTopicIntroType ) => ({
  type: 'DELETE_TOPIC_INTRO_SAGA',
  payload: payload
});

export const DELETE_PUZZLES_SAGA = ( payload: deletePuzzlestype ) => ({
  type: 'DELETE_PUZZLES_SAGA',
  payload: payload
});




// UPDATE




export const UPDATE_QUESTIONS = (payload: { [key: string]: any }) => ({
  type: 'UPDATE_QUESTIONS',
  payload: payload
});


export const UPDATE_TOPIC_INTROS = (payload: { [key: string]: any }) => ({
  type: 'UPDATE_TOPIC_INTROS',
  payload: payload
});




// OTHER




export const SHOW_ERROR = () => ({
  type: 'SHOW_ERROR',
  payload: true
});

export const DO_TEST_SAGA = () => ({
  type: 'DO_TEST_SAGA',
  payload: true
});

export const RESET = () => ({
  type: 'RESET',
  payload: true
});


export const UPLOAD_DATA_SAGA = (payload: { sub: string; }) => ({
  type: 'UPLOAD_DATA_SAGA',
  payload: payload
});




 


