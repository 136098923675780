import React                  from 'react';
import { connect }            from 'react-redux';
import { Dispatch }           from "redux";
import { reducerType }        from 'reducer';
import { ActionTypes }        from 'actions/types';
import { colors }             from 'utils/colors';
import resetStates            from 'functions/reset_states';
import submitPuzzle           from 'functions/forms/submit_puzzle';
import { makeStyles }         from '@material-ui/core/styles';
import { puzzleStateType }    from 'types';
import { SET_PUZZLE_STATE }   from 'actions/puzzleActions';

const backendErr = "Data not uploaded dut to error";

const useStyles = makeStyles(() => ({
    root: {
        position:        'fixed',
        top:             0,
        height:          '100vh',
        width:           '100vw',
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
        backgroundColor: '#00000077'
    },
    form: {
        display:         'flex',
        maxHeight:       570,
        flexFlow:        'column',
        border:          '1px solid #DDD',
        backgroundColor: '#FFF',
        padding:         20,
        overflow:        'scroll',
        '&::-webkit-scrollbar': {
            width: '0.5em',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: `#BBB`,
          borderRadius:    10
        }
    },
    error: {
       display:         'flex',
       alignItems:      'center',
       justifyContent:  'center',
       cursor:          'default',
       height:          40,
       fontSize:        18,
       color:           '#E00'
    },
    input: {
        width:      '45vw',
        height:     60,
        minHeight:  60,
        minWidth:   350,
        fontSize:   18,
        margin:     8
    },
    button: {
       width:           200,
       height:          45,
       minHeight:       45,
       margin:          8,
       display:         'flex',
       alignItems:      'center',
       justifyContent:  'center',
       color:           '#FFF',
       backgroundColor: colors.primary,
       fontWeight:      'bold',
       cursor:          'pointer',
       '&:hover': {
          opacity: 0.9
       }
    },
    xContainer: {
        height:          30,
        width:           '100%',
        display:         'flex',
        justifyContent:  'right',
    },
    x: {
        height:          30,
        width:           30,
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
        backgroundColor: colors.primary,
        color:           '#FFF',
        fontWeight:      'bold',
        cursor:          'pointer'

    }
}))


type PuzzleFormPropsType = {
    puzzleState:    puzzleStateType;
    error:          string;
    showError:      boolean;
    setPuzzleState: ( val: puzzleStateType ) => void;
}

const PuzzleForm: React.FC<PuzzleFormPropsType> = ({ 
    puzzleState, 
    error, 
    showError, 
    setPuzzleState 
}) => {

    const classes = useStyles();
    

    const changeInputValue = (e: React.FormEvent) => {

        e.preventDefault();
    
        const target = e.target as HTMLInputElement;
    
        if (target.id === 'puzzletype')          { setPuzzleState({ ...puzzleState, puzzletype: target.value }) }
        if (target.id === 'puzzle_type_text')    { setPuzzleState({ ...puzzleState, puzzle_type_text: target.value }) }
        if (target.id === 'topic')               { setPuzzleState({ ...puzzleState, topic: target.value }) }
        if (target.id === 'description_teacher') { setPuzzleState({ ...puzzleState, description_teacher: target.value }) }
        if (target.id === 'description_student') { setPuzzleState({ ...puzzleState, description_student: target.value }) }
        if (target.id === 'extra_url')           { setPuzzleState({ ...puzzleState, extra_url: target.value }) }
        if (target.id === 'page')                { setPuzzleState({ ...puzzleState, page: target.value }) }
        if (target.id === 'img')                 { setPuzzleState({ ...puzzleState, img: target.value }) }
        if (target.id === 'e1')                  { setPuzzleState({ ...puzzleState, e1: target.value }) }
        if (target.id === 'e2')                  { setPuzzleState({ ...puzzleState, e2: target.value }) }
        if (target.id === 'e3')                  { setPuzzleState({ ...puzzleState, e3: target.value }) }
        if (target.id === 'e4')                  { setPuzzleState({ ...puzzleState, e4: target.value }) }
        if (target.id === 'e5')                  { setPuzzleState({ ...puzzleState, e5: target.value }) }
        if (target.id === 'e6')                  { setPuzzleState({ ...puzzleState, e6: target.value }) }
        if (target.id === 'e7')                  { setPuzzleState({ ...puzzleState, e7: target.value }) }
        if (target.id === 'e8')                  { setPuzzleState({ ...puzzleState, e8: target.value }) }
        if (target.id === 'e9')                  { setPuzzleState({ ...puzzleState, e9: target.value }) }
        if (target.id === 'e10')                 { setPuzzleState({ ...puzzleState, e10: target.value }) }
        if (target.id === 'e11')                 { setPuzzleState({ ...puzzleState, e11: target.value }) }
        if (target.id === 'e12')                 { setPuzzleState({ ...puzzleState, e12: target.value }) }
        if (target.id === 'e13')                 { setPuzzleState({ ...puzzleState, e13: target.value }) }
        if (target.id === 'e14')                 { setPuzzleState({ ...puzzleState, e14: target.value }) }
        if (target.id === 'e15')                 { setPuzzleState({ ...puzzleState, e15: target.value }) }
        if (target.id === 'e16')                 { setPuzzleState({ ...puzzleState, e16: target.value }) }
    
    }


    return(
    <div className = { classes.root }>
        <div className = { classes.form }>
            <div className = { classes.xContainer }
            >
                <div 
                className = { classes.x }
                onClick   = { () => { resetStates('') }}
                >
                    x
                </div>
            </div>
            <div className = { classes.error }>
                { showError ? backendErr : error }
            </div>
            {
                Object.keys(puzzleState).map((val) => {
                    if ( val === 'id' ) {
                        return(<div key = { '!' }/>)
                    }
                    return(
                        <textarea 
                           key         = { val }
                           className   = { classes.input }
                           id          = { val }
                           placeholder = { val }
                           value       = { puzzleState[`${ val }`] }
                           onChange    = { changeInputValue }
                           style       = {{
                               height : (val === 'question' || val === 'answer_choices') ? 90 : undefined
                           }}
                        />
                    )
                })
            }
            <div
               className = { classes.button }
               onClick   = { submitPuzzle }
            >
                submit
            </div>
        </div>
    </div>
    )
}

const mapStateToProps = (reducer: reducerType) => {
    return {
        puzzleState: reducer.puzzles,
        error:       reducer.components.error,
        showError:   reducer.components.showError
    }
  };
 
  const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
     setPuzzleState: (payload: puzzleStateType) => dispatch(SET_PUZZLE_STATE(payload)),
 });
 
  export default connect(mapStateToProps, mapDispatchToProps)(PuzzleForm);
 