import { ActionTypes }      from 'actions/types';
import { puzzleStateType } from 'types';

export const initialPuzzleState: puzzleStateType = {
    puzzletype:          "",
    puzzle_type_text:    "",
    topic:               "",
    description_teacher: "",
    description_student: "",
    extra_url:           "",
    page:                "",
    img:                 "",
    e1:                  "",
    e2:                  "",
    e3:                  "",
    e4:                  "",
    e5:                  "",
    e6:                  "",
    e7:                  "",
    e8:                  "",
    e9:                  "",
    e10:                 "",
    e11:                 "",
    e12:                 "",
    e13:                 "",
    e14:                 "",
    e15:                 "",
    e16:                 "",
    id:                  -1
};


const puzzleReducer = (state = initialPuzzleState, action: ActionTypes) => {

  switch (action.type) {


    case 'SET_PUZZLE_STATE':
    {
       return {
         ...action.payload 
       }
    }

    default: return state;
  }
};


export { puzzleReducer };
