import { store }                      from 'index'
import { submitFreeUserFormType }     from "actions/types";
import { SET_ERROR }                  from 'actions/componentActions';
import { SUBMIT_FREE_USER_FORM_SAGA } from 'actions';

const submitFreeUser = () => {

    const state           = store.getState();
    const freeAccessState = state.freeAccess;
    const error           = state.components.error;
    const sub             = state.user.sub;
    const setError        = (payload: string) => store.dispatch(SET_ERROR(payload));
    const submitForm      = (payload: submitFreeUserFormType) => store.dispatch(SUBMIT_FREE_USER_FORM_SAGA(payload));

    if ( freeAccessState.emails.length === 0 ) {
       setError('enter at least one email address');
       return;
    }
    if ( !!error.length ) {
      setError('')
    }
    submitForm({ ...freeAccessState, sub: sub });
 }

 export default submitFreeUser;