import { store }                from 'index'
import { submitPuzzleFormType } from "actions/types";
import { SUBMIT_PUZZLE_SAGA }   from 'actions';
import { SET_FORM }             from 'actions/componentActions';

const submitPuzzle = () => {

    const state            = store.getState();
    const puzzleState      = state.puzzles;
    const sub              = state.user.sub;
    const submitPuzzleForm = (payload: submitPuzzleFormType) => store.dispatch(SUBMIT_PUZZLE_SAGA(payload));

    store.dispatch(SET_FORM(''));    
    submitPuzzleForm({ newPuzzle: puzzleState, sub: sub });
 }

 export default submitPuzzle;


