import { ActionTypes }         from 'actions/types';
import { topicIntroStateType } from 'types';

export const initialTopicIntroState: topicIntroStateType = {
    topic:        '',
    sub_topic:    '',
    youtube:      '',
    img:          '',
    teacher_text: '',
    student_text: '',
    id:           -1,
};


const topicIntroReducer = (state = initialTopicIntroState, action: ActionTypes) => {

  switch (action.type) {


    case 'SET_TOPIC_INTRO_STATE':
    {
       return {
         ...action.payload 
       }
    }

    default: return state;
  }
};


export { topicIntroReducer };
