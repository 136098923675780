const arr: {[key: string]: any}[] = [
  {
    "school_name": "עידוד",
    "grade": "1,2,3,4,5,6",
    "contact_person": "רויטל לב אלקריף",
    "contact_person_phone": "054-8020119",
    "contact_person_email": "revi71171@gmail.com",
    "semel_mosad": "420471"
  },
  {
    "school_name": "שחקים מחוננים",
    "grade": "1,2,3,4,5,6,7",
    "contact_person": "",
    "semel_mosad": "288050"
  },
  {
    "school_name": "אורות בנות",
    "grade": "1,2,3,4,5,6",
    "contact_person": "חנה אוקנין",
    "contact_person_phone": "050-2221906",
    "contact_person_email": "chodna770@gmail.com",
    "semel_mosad": "514968"
  },
  {
    "school_name": "אור האמת - בנות",
    "grade": "1,2,3,4,5,6,7",
    "contact_person": "ורד גספן",
    "contact_person_phone": "050-4152094",
    "contact_person_email": "veredg3@gmail.com",
    "semel_mosad": "481952"
  },
  {
    "school_name": "בנות מלך",
    "grade": "1,2,3,4,5,6,7",
    "contact_person": "",
    "semel_mosad": "442582"
  },
  {
    "school_name": "חיידר אידיש חב\"ד",
    "grade": "1,2,3,4,5,6,7",
    "contact_person": "משה דוד מרקובסקי",
    "contact_person_phone": "052-7614351",
    "contact_person_email": "5800654@5800654.com",
    "semel_mosad": "229617"
  },
  {
    "school_name": "אור הגנוז בנים",
    "grade": "1,2,3,4,5,6,7",
    "contact_person": "אליהו אשר גרליץ",
    "contact_person_phone": "052-7655126",
    "contact_person_email": "ashergerlitz@gmail.com",
    "semel_mosad": "511964"
  },
  {
    "school_name": "אורחות אמותינו(בנות)",
    "grade": "1,2,3,4,5,6,7",
    "contact_person": "",
    "semel_mosad": "499731"
  },
  {
    "school_name": "אלסלאם",
    "grade": "1,2,3,4,5,6,7",
    "contact_person": "יונס אל אטרש",
    "contact_person_phone": "054-6304870",
    "contact_person_email": "younis5@walla.co.il",
    "semel_mosad": "615294"
  },
  {
    "school_name": "אשירה",
    "grade": "1,2,3",
    "contact_person": "תחיה וולך",
    "contact_person_phone": "052-7160086",
    "contact_person_email": "tvallach@gmail.com",
    "semel_mosad": "630194"
  },
  {
    "school_name": "באר",
    "grade": "1,2,3",
    "contact_person": "אורית לסר",
    "contact_person_phone": "055-6632541",
    "contact_person_email": "mamadzarfatit@gmail.com",
    "semel_mosad": "630244"
  },
  {
    "school_name": "באר החיים",
    "grade": "1,2,3,4,5,6,7",
    "contact_person": "",
    "semel_mosad": "214312"
  },
  {
    "school_name": "בארי בשדה",
    "grade": "1,2,3,4,5,6",
    "contact_person": "שלמה אלבז",
    "contact_person_phone": "054-2115835",
    "contact_person_email": "shlomi1770@gmail.com",
    "semel_mosad": "482331"
  },
  {
    "school_name": "ביכורים",
    "grade": "1,2,3,4,5,6",
    "contact_person": "צופית שריג",
    "contact_person_phone": "050-6280259",
    "contact_person_email": "etsarig@gmail.com",
    "semel_mosad": "212167"
  },
  {
    "school_name": "ביס חבד ק חיים",
    "grade": "1,2,3,4,5,6",
    "contact_person": "משה אסולין",
    "contact_person_phone": "050-6882588",
    "contact_person_email": "m.assulin@gmail.com",
    "semel_mosad": "312603"
  },
  {
    "school_name": "בית חיה",
    "grade": "1,2,3,4,5,6",
    "contact_person": "ורדה בת ציון אקסלרוט",
    "contact_person_phone": "054-7770834",
    "contact_person_email": "vardachabad@gmail.com",
    "semel_mosad": "313585"
  },
  {
    "school_name": "בית-אל בנות",
    "grade": "1,2,3,4,5,6",
    "contact_person": "מיכל צרום",
    "contact_person_phone": "050-9156666",
    "contact_person_email": "michal.k160@gmail.com",
    "semel_mosad": "111849"
  },
  {
    "school_name": "בנות ירושלים",
    "grade": "1,2,3,4,5,6",
    "contact_person": "בתיה מלאך",
    "contact_person_phone": "050-8752521",
    "contact_person_email": "bitimalach@gmail.com",
    "semel_mosad": "611541"
  },
  {
    "school_name": "גבעת רם",
    "grade": "1,2,3,4,5,6,7",
    "contact_person": "נורית פרידמן",
    "contact_person_phone": "050-2637788",
    "contact_person_email": "thefridmans@gmail.com",
    "semel_mosad": "617050"
  },
  {
    "school_name": "הר נוף ממד בנות",
    "grade": "1,2,3,4,5,6,7",
    "contact_person": "נעה מזרחי",
    "contact_person_phone": "052-3590102",
    "contact_person_email": "gidnoa@gmail.com",
    "semel_mosad": "113217"
  },
  {
    "school_name": "חורב",
    "grade": "1,2,3",
    "contact_person": "צפורה מימון",
    "contact_person_phone": "052-3003351",
    "contact_person_email": "zipora27@gmail.com",
    "semel_mosad": "430884"
  },
  {
    "school_name": "יראת ה''",
    "grade": "1,2,3,4,5,6,7",
    "contact_person": "",
    "semel_mosad": "722801"
  },
  {
    "school_name": "ישורון",
    "grade": "1,2,3,4,5,6",
    "contact_person": "שלמה ווזנה",
    "contact_person_phone": "055-6616951",
    "contact_person_email": "shlomi1973@walla.com",
    "semel_mosad": "311399"
  },
  {
    "school_name": "לחינוך מיוחד - חדש",
    "grade": "1,2",
    "contact_person": "באסם אלחואגרה",
    "contact_person_phone": "052-6650049",
    "contact_person_email": "gmabasim@gmail.com",
    "semel_mosad": "618686"
  },
  {
    "school_name": "מ\"ד מורשה",
    "grade": "1,2,3,4,5,6",
    "contact_person": "קרן אביאל",
    "contact_person_phone": "054-4243535",
    "contact_person_email": "avielkeren@gmail.com",
    "semel_mosad": "411769"
  },
  {
    "school_name": "מורשת רחל",
    "grade": "1,2,3,4,5,6,7",
    "contact_person": "",
    "semel_mosad": "499889"
  },
  {
    "school_name": "מימון מד",
    "grade": "1,2,3,4,5,6",
    "contact_person": "יעל כהן",
    "contact_person_phone": "050-7513345",
    "contact_person_email": "yzmigc@gmail.com",
    "semel_mosad": "510081"
  },
  {
    "school_name": "ממ\"ד בנות נחלת צבי",
    "grade": "1,2,3,4,5,6",
    "contact_person": "מירב אהרונוב",
    "contact_person_phone": "052-5665351",
    "contact_person_email": "meravem@gmail.com",
    "semel_mosad": "413989"
  },
  {
    "school_name": "מעלה התורה בנים",
    "grade": "1,2,3,4,5,6,7",
    "contact_person": "אברהם מיארה",
    "contact_person_phone": "054-6758817",
    "contact_person_email": "avraham200@gmail.com",
    "semel_mosad": "113308"
  },
  {
    "school_name": "מעלות דפנה יד המורה",
    "grade": "1,2,3,4,5,6",
    "contact_person": "אסתר עזרא",
    "contact_person_phone": "055-8844072",
    "contact_person_email": "etti1974@gmail.com",
    "semel_mosad": "111278"
  },
  {
    "school_name": "נוה עציון בנות",
    "grade": "1,2,3,4,5,6",
    "contact_person": "שירה בן ישראל",
    "contact_person_phone": "054-6475580",
    "contact_person_email": "shirab80@gmail.com",
    "semel_mosad": "111898"
  },
  {
    "school_name": "נועם בנים ק.משה",
    "grade": "1,2,3,4,5,6,7",
    "contact_person": "אורית דהן",
    "contact_person_phone": "050-6202844",
    "contact_person_email": "oritdahan50@gmail.com",
    "semel_mosad": "112003"
  },
  {
    "school_name": "קהילות יעקב-בני יוסף",
    "grade": "1,2,3,4,5",
    "contact_person": "",
    "semel_mosad": "743468"
  },
  {
    "school_name": "שירת מרים",
    "grade": "1,2,3,4,5,6,7",
    "contact_person": "מליה גולדשטיין",
    "contact_person_phone": "052-3372277",
    "contact_person_email": "malyagol@gmail.com",
    "semel_mosad": "430199"
  },
  {
    "school_name": "שלהבת הדרום(בנות)",
    "grade": "1,2,3,4,5,6,7",
    "contact_person": "",
    "semel_mosad": "614982"
  },
  {
    "school_name": "ת\"ת אור דוד",
    "grade": "1,2,3,4,5,6,7",
    "contact_person": "",
    "semel_mosad": "514109"
  },
  {
    "school_name": "ת\"ת ברסלב אלעד",
    "grade": "1,2,3,4,5,6,7",
    "contact_person": "ברוך ידידיה אלול",
    "contact_person_phone": "054-7227140",
    "contact_person_email": "tkuktkuk30@gmail.com",
    "semel_mosad": "611772"
  },
  {
    "school_name": "ת\"ת נחמת ציון",
    "grade": "1,2,3,4,5,6,7",
    "contact_person": "",
    "semel_mosad": "134676"
  },
  {
    "school_name": "תורת חסד",
    "grade": "1,2,3",
    "contact_person": "מרגלית ברהום",
    "contact_person_phone": "054-8418325",
    "contact_person_email": "margushab@gmail.com",
    "semel_mosad": "630129"
  },
  {
    "school_name": "תלמוד תורה דביר",
    "grade": "1,2,3,4,5,6,7",
    "contact_person": "מיכאל בן שלמה",
    "contact_person_phone": "052-3556829",
    "contact_person_email": "mbs234@gmail.com",
    "semel_mosad": "174680"
  },
  {
    "school_name": "אילת השחר",
    "grade": "2,3,4,5,6,7",
    "contact_person": "יואב חנו",
    "contact_person_phone": "055-6684443",
    "contact_person_email": "mosd22@walla.com",
    "semel_mosad": "612853"
  },
  {
    "school_name": "אור מנחם",
    "grade": "2,3,4,5,6",
    "contact_person": "ענבל רחימי",
    "contact_person_phone": "052-7705677",
    "contact_person_email": "rachimi770@gmail.com",
    "semel_mosad": "510065"
  },
  {
    "school_name": "גפנים",
    "grade": "2,3,4,5,6",
    "contact_person": "רעות חן",
    "contact_person_phone": "050-5667008",
    "contact_person_email": "reutgfanim@gmail.com",
    "semel_mosad": "112599"
  },
  {
    "school_name": "תקוע",
    "grade": "2,3",
    "contact_person": "עפרה נתן",
    "contact_person_phone": "054-6983400",
    "contact_person_email": "natanofra@gmail.com",
    "semel_mosad": "112607"
  },
  {
    "school_name": "א.ד.רוטשילד",
    "grade": "3,4,5,6",
    "contact_person": "יוסף אוחנה",
    "contact_person_phone": "050-6202847",
    "contact_person_email": "oyossi120@gmail.com",
    "semel_mosad": "110619"
  },
  {
    "school_name": "הר חומה ממ\"ד",
    "grade": "3,4,5,6",
    "contact_person": "הודיה עמירה",
    "contact_person_phone": "052-6002286",
    "contact_person_email": "hodayamira@gmail.com",
    "semel_mosad": "267344"
  },
  {
    "school_name": "חב\"ד חיה מושקא",
    "grade": "3,4,5,6,7",
    "contact_person": "רחל קיז'נר",
    "contact_person_phone": "052-7707093",
    "contact_person_email": "kishne@gmail.com",
    "semel_mosad": "416727"
  },
  {
    "school_name": "יפה נוף ממ\"ד",
    "grade": "3,4,5,6",
    "contact_person": "חגית חדד",
    "contact_person_phone": "050-9309367",
    "contact_person_email": "hagithadad1980@gmail.com",
    "semel_mosad": "338723"
  },
  {
    "school_name": "מבוא גליל-איילת השחר",
    "grade": "3,4,5,6",
    "contact_person": "נגה שמחי",
    "contact_person_phone": "054-4885941",
    "contact_person_email": "ensimhi@gmail.com",
    "semel_mosad": "210062"
  },
  {
    "school_name": "נזירות נצרת",
    "grade": "3,4,5,6",
    "contact_person": "ראמי סרור",
    "contact_person_phone": "054-7444663",
    "contact_person_email": "ramisr@hotmail.com",
    "semel_mosad": "217257"
  },
  {
    "school_name": "אח\"י תקוע 2",
    "grade": "4,5,6,7",
    "contact_person": "אופיר נחמיה לוגסי",
    "contact_person_phone": "052-4591291",
    "contact_person_email": "ofirliron1@gmail.com",
    "semel_mosad": "615955"
  },
  {
    "school_name": "אלנהדה",
    "grade": "4,5,6,7",
    "contact_person": "רפיף עתאמנה",
    "contact_person_phone": "050-6511322",
    "contact_person_email": "ladyrafeef@gmail.com",
    "semel_mosad": "318147"
  },
  {
    "school_name": "חט\"ב יונתן",
    "grade": "4,5,6",
    "contact_person": "סוזי סיבוני",
    "contact_person_phone": "050-7308657",
    "contact_person_email": "suzy.sibony@gmail.com",
    "semel_mosad": "444414"
  },
  {
    "school_name": "יסודי ג' שפרעם",
    "grade": "4,5",
    "contact_person": "נג'אח ח'אזם",
    "contact_person_phone": "050-7362446",
    "contact_person_email": "najahkh1205@walla.com",
    "semel_mosad": "219105"
  },
  {
    "school_name": "מ\"מ ע\"ש יצחק נבון",
    "grade": "4,5,6",
    "contact_person": "נוגה יוכבד מור",
    "contact_person_phone": "053-2850328",
    "contact_person_email": "nogayochymor@gmail.com",
    "semel_mosad": "113639"
  },
  {
    "school_name": "מ\"מ רעות",
    "grade": "4,5,6",
    "contact_person": "גילה זיסקינד",
    "contact_person_phone": "050-7783489",
    "contact_person_email": "siskind.gila@gmail.com",
    "semel_mosad": "313759"
  },
  {
    "school_name": "מחוננים אום אל פחם",
    "grade": "4,5,6,7",
    "contact_person": "סומיה אגבאריה",
    "contact_person_phone": "050-9499405",
    "contact_person_email": "somaya-nfri@hotmail.com",
    "semel_mosad": "317107"
  },
  {
    "school_name": "מעלה התורה בנות",
    "grade": "4,5,6",
    "contact_person": "אילת כהן",
    "contact_person_phone": "054-5630814",
    "contact_person_email": "ayeletmenahelet@gmail.com",
    "semel_mosad": "131029"
  },
  {
    "school_name": "מעלה מכמש",
    "grade": "4,5,6",
    "contact_person": "יעל שפט",
    "contact_person_phone": "054-4553770",
    "contact_person_email": "yashafat@gmail.com",
    "semel_mosad": "166074"
  },
  {
    "school_name": "שדה יעקב מד",
    "grade": "4,5,6,7",
    "contact_person": "שרה אסתר רפפורט",
    "contact_person_phone": "054-6644544",
    "contact_person_email": "sarirap10@gmail.com",
    "semel_mosad": "212290"
  },
  {
    "school_name": "שדות",
    "grade": "4,5",
    "contact_person": "הדר הודיה כץ בן שלום",
    "contact_person_phone": "050-7947149",
    "contact_person_email": "hadar.hbs@gmail.com",
    "semel_mosad": "616730"
  },
  {
    "school_name": "שלהבות חב\"ד",
    "grade": "4,5,6",
    "contact_person": "סטרנא שרה גורליק",
    "contact_person_phone": "052-7707222",
    "contact_person_email": "sariprus@gmail.com",
    "semel_mosad": "616474"
  },
  {
    "school_name": "המשותף",
    "grade": "5,6",
    "contact_person": "לוטן וקסלר",
    "contact_person_phone": "050-5985062",
    "contact_person_email": "lotanvek@gmail.com",
    "semel_mosad": "581165"
  },
  {
    "school_name": "יסודי ג' - נחף",
    "grade": "5,6",
    "contact_person": "ח'אלד עוסמאן",
    "contact_person_phone": "050-5933726",
    "contact_person_email": "kh5699@outlook.co.il",
    "semel_mosad": "219790"
  },
  {
    "school_name": "ממ\"ד ג.מרדכי",
    "grade": "5,6",
    "contact_person": "אבישי יהודה פרינץ",
    "contact_person_phone": "052-8308131",
    "contact_person_email": "vishay27@gmail.com",
    "semel_mosad": "430025"
  },
  {
    "school_name": "בית-אל בנים",
    "grade": "6,7",
    "contact_person": "ירון גיאת",
    "contact_person_phone": "052-6503156",
    "contact_person_email": "giatyb@gmail.com",
    "semel_mosad": "113324"
  },
  {
    "school_name": "ימין הרצוג",
    "grade": "6,7",
    "contact_person": "דוד נענה",
    "contact_person_phone": "055-6655978",
    "contact_person_email": "david-n@edueilat.org",
    "semel_mosad": "610048"
  },
  {
    "school_name": "ישיבה תיכונית בנ\"ע עלי",
    "grade": "7",
    "contact_person": "עובדיה בן משה",
    "contact_person_phone": "052-6051600",
    "contact_person_email": "ovadya11@gmail.com",
    "semel_mosad": "641498"
  },
  {
    "school_name": "מקיף אורט מעלות",
    "grade": "7",
    "contact_person": "שולמית אליקן",
    "contact_person_phone": "050-2017103",
    "contact_person_email": "shulyelikan@gmail.com",
    "semel_mosad": "240465"
  },
  {
    "school_name": "אמית אורי",
    "grade": "7",
    "contact_person": "רוחמה ווגל",
    "contact_person_phone": "052-6043396",
    "contact_person_email": "ruvogel@gmail.com",
    "semel_mosad": "338616"
  },
  {
    "school_name": "בית ספר תיכון מקצועי",
    "grade": "7",
    "contact_person": "ספיר איובי",
    "contact_person_phone": "050-8899300",
    "contact_person_email": "sapiriub@gmail.com",
    "semel_mosad": "120113"
  },
  {
    "school_name": "חטב מכללת אורט גבעת רם ירושלים",
    "grade": "7",
    "contact_person": "מיכל דלאל",
    "contact_person_phone": "054-2020332",
    "contact_person_email": "michald@gram.ort.org.il",
    "semel_mosad": "141010"
  },
  {
    "school_name": "ישיבה תיכונית הר ברכה",
    "grade": "7",
    "contact_person": "יוגב כהן",
    "contact_person_phone": "052-7503740",
    "contact_person_email": "yogev@yhb.org.il",
    "semel_mosad": "482844"
  },
  {
    "school_name": "מקיף אעבלין",
    "grade": "7",
    "contact_person": "פהד חאג' אחמד",
    "contact_person_phone": "050-7777714",
    "contact_person_email": "fahed-haj@hotmail.com",
    "semel_mosad": "247239"
  },
  {
    "school_name": "עתיד מקיף ממ\"ד יגאל אלון",
    "grade": "7",
    "contact_person": "אסף משולם",
    "contact_person_phone": "054-6338644",
    "contact_person_email": "asfmem@gmail.com",
    "semel_mosad": "444943"
  }
]

  export default arr;