import React               from 'react';
import openModal           from 'functions/modal/open_modal';
import duplicate           from 'functions/tables/duplicate';
import { makeStyles }      from '@material-ui/core/styles';
import { 
  deleteStateType, 
  duplicationStateType, 
  puzzleStateType 
}                          from '../../../types';


var clsx = require('classnames')


const useStyles = makeStyles(() => ({
    item: {
        flex:            1,
        height:          '100%',
        display:         'flex',
        alignItems:      'center',
        borderLeft:      '1px solid #999',
        borderTop:       '1px solid #999',
        flexWrap:        'wrap',
        overflowX:       'auto',
        transition:      'opacity 0.3s',
      //  backgroundColor: '#FAFAFA',
        paddingLeft:     8,
        '&::-webkit-scrollbar': {
          height: '0.5em',
          width: '0.5em',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: `#BBB`,
          borderRadius:    10
        },
        '&:hover': {
            backgroundColor: '#EEF'
        }
    },
    note: {
        flex:         1,
        paddingRight: 8
    },
}))



type ItemPropsType = {
    variant:            string;
    marked:             boolean;
    index:              number;
    duplicateState:     duplicationStateType;
    obj:                { [key: string]: any };
    widths:             { [key: string]: number };
    deleteState:        deleteStateType;
    setPuzzleState:     ( val: puzzleStateType ) => void;
    setDeleteState:     ( val: deleteStateType ) => void;
}



const Item: React.FC<ItemPropsType> = ({ 
     obj,
     marked,
     variant,
     index,
     duplicateState,
     widths,
     deleteState,
     setPuzzleState,
     setDeleteState,
    }) => {

    const classes = useStyles();


    const value = React.useMemo(() => {
        return (!!obj[variant] || obj[variant] === 0) ? obj[variant] : ''
    },[obj, variant])



    const handleClick = () => {
  
        if ( deleteState.deleteMode ) {

           let newArr = [...deleteState.selectedItems]
           const id   = obj['id'];

           if ( marked ) {
             newArr.splice(newArr.indexOf(id), 1)
           }
           else {
             newArr.push(id)
           }

           setDeleteState({
               ...deleteState,
               selectedItems: newArr
           })
        }
        else if ( duplicateState.duplicateMode ) {
          duplicate('createPuzzle', obj)
        } 
        else {
            setPuzzleState(obj);
            openModal(variant, `${ value }`);
        }
    }

    


    return(
        <div 
           className = { (variant === 'comments' || variant === 'notes') ? clsx( classes.item, classes.note ) : classes.item } 
           onClick   = { handleClick }
           style     = {{ 
            minWidth:        !!widths[variant] ? widths[variant] : 200,
            maxWidth:        variant === 'id' ? 30 : undefined,
            cursor:          'pointer'
          }}
        >
           { value }
        </div>
    )
}



export default Item;