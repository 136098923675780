import { EDIT_TOPIC_INTRO_SAGA } from 'actions';
import { editTopicIntroType }    from 'actions/types';
import { store }                 from 'index'

const editTopicIntro = () => {

    const state           = store.getState();
    const topicIntroState = state.topicIntros;
    const topicArr        = state.content.topicIntros;
    const sub             = state.user.sub;
    const editTopicIntro  = (payload: editTopicIntroType) => store.dispatch(EDIT_TOPIC_INTRO_SAGA(payload));

    editTopicIntro({ sub: sub, topicIntroState: topicIntroState, topicArr: topicArr });
 }

 export default editTopicIntro;


