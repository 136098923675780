import { SET_MODAL_STATE } from 'actions/modalActions';
import { store }           from 'index'

const openModal = (
    variant: string,
    value:   string
) => {
    
    if ( variant !== 'id' ) {
        store.dispatch(SET_MODAL_STATE({
            openModal:    true,
            modalVariant: variant,
            modalValue:   value
        }))
    }
}

export default openModal;