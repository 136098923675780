import { store }                   from 'index'
import { submitTopicIntroType }    from "actions/types";
import { componentsStateType }     from 'reducer/types';
import { SUBMIT_TOPIC_INTRO_SAGA } from 'actions';
import { 
    SET_COMPONENTS_STATE, 
    SET_ERROR
 }                                 from 'actions/componentActions';


const submitTopicIntro = () => {

    const state              = store.getState();
    const topicIntroState    = state.topicIntros;
    const sub                = state.user.sub;
    const componentsState    = state.components;
    const setComponentsState = (payload: componentsStateType) => store.dispatch(SET_COMPONENTS_STATE(payload));
    const setError           = (payload: string) => store.dispatch(SET_ERROR(payload));
    const submitForm         = (payload: submitTopicIntroType) => store.dispatch(SUBMIT_TOPIC_INTRO_SAGA(payload));

    if ( topicIntroState.topic.length === 0 ) {
       setError('you must enter a topic');
       return;
    }
   
    setComponentsState({
        ...componentsState,
        error: '',
        form:  ''
    })

    submitForm({ newTopicIntro: topicIntroState, sub: sub });
 }

 export default submitTopicIntro;