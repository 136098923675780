import { EDIT_PUZZLES_SAGA } from 'actions';
import { editPuzzlesType }   from 'actions/types';
import { store }             from 'index'

const editPuzzle = () => {

    const state           = store.getState();
    const puzzleState     = state.puzzles;
    const puzzleArr       = state.content.puzzles;
    const sub             = state.user.sub;
    const editPuzzles     = (payload: editPuzzlesType) => store.dispatch(EDIT_PUZZLES_SAGA(payload));

    editPuzzles({ sub: sub, puzzleState: puzzleState, puzzlesArr: puzzleArr })
}

 export default editPuzzle;


