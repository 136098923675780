import React                      from 'react';
import FormControl                from '@material-ui/core/FormControl';
import FormHelperText             from '@material-ui/core/FormHelperText';
import IconButton                 from '@material-ui/core/IconButton';
import InputAdornment             from '@material-ui/core/InputAdornment';
import Input                      from '@material-ui/core/Input';
import InputLabel                 from '@material-ui/core/InputLabel';
import Visibility                 from '@material-ui/icons/Visibility';
import VisibilityOff              from '@material-ui/icons/VisibilityOff';
import { makeStyles }             from '@material-ui/core/styles';
//import { colors }                 from '../../utils/colors';


const useStyles = makeStyles(({

  password: {
    marginTop:  10,
    fontFamily: 'Raleway',
    fontWeight: 'bold',
    fontSize:   15
  }
}));


type PasswordInputProps = {
    password:         string;
    showHelperText:   boolean;
    textFieldStyle:   { width: string };
    changeInputValue: (e: React.FormEvent) => void;
}

const PasswordInput: React.FC<PasswordInputProps> = ({
     password,
     showHelperText,
     textFieldStyle,
     changeInputValue
}) => { 

  const classes = useStyles();


  const [showPassword, setShowPassword] = React.useState(false)

 

  return (
        <FormControl>
              <InputLabel htmlFor="password" >Password</InputLabel>
              <Input 
                id           = "password"
                className    = { classes.password } 
                style        = { textFieldStyle }
                type         = { showPassword ? 'text' : 'password' }
                value        = { password }
                autoComplete = "current-password"
                onChange     = { changeInputValue }
                endAdornment = {
                  <InputAdornment position = "end">
                    <IconButton
                      aria-label = "toggle password visibility"
                      onClick    = { () => { setShowPassword(!showPassword) } }
                    >
                      { showPassword ? <Visibility /> : <VisibilityOff /> }
                    </IconButton>
                  </InputAdornment>
                }
              />
              {
                  showHelperText
                  &&
                  <FormHelperText id = "password">must be at least 8 characters long</FormHelperText> 
              }
        </FormControl>
    )
  
}


export default PasswordInput;




