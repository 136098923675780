import React                   from 'react';
import { connect }             from 'react-redux';

import checkGroup              from 'utils/auth/check_group';
import FreeUserForm            from 'components/forms/FreeUserForm';
import PracticeQuestionsForm   from 'components/forms/PracticeQuestionsForm';
import TopicIntroForm          from 'components/forms/TopicIntroForm';
import PuzzleForm              from 'components/forms/PuzzleForm';
import NavBar                  from 'components/navBar';
import Modal                   from 'components/modal';
import SuccessPage             from './SuccessPage';
import Loader                  from 'components/Loader';
import QuestionsTable          from 'components/tables/questions';
import TopicIntroTable         from 'components/tables/topic_intros';
import PuzzlesTable            from 'components/tables/puzzles';


import onLoad                  from 'functions/onload';
import setUser                 from 'functions/set_user';

import { reducerType }         from 'reducer';

import { componentsStateType } from 'reducer/types';
import { modalStateType }      from 'types';

import { makeStyles }          from '@material-ui/core/styles';



const useStyles = makeStyles(() => ({
    root : {
        position:        'fixed',
        left:            0,
        minHeight:       '100vh',
        minWidth:        '100vw',
        maxHeight:       '100vh',
        maxWidth:        '100vw',
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
        flexFlow:        'column',
        backgroundColor: '#FFF',
        overflowY:       'scroll',
        overflowX:       'hidden',
    },
    loading: {
        margin:   20,
        fontSize: 20
    }
}))

type RootPropsType = {
    authData: {
        attributes: {
          sub:           string;
          email:         string;
          'custom:name': string;
        }
    };
    componentsState: componentsStateType;
    modalState:      modalStateType;
}

const Root: React.FC<RootPropsType> = ({
    authData, 
    componentsState,
    modalState
 }) => {


    const classes = useStyles();

    const [size, setSize] = React.useState([0,0]);  
    const [userIsAdmin, setUserIsAdmin] = React.useState('')
    const [userIsDev, setUserIsDev]     = React.useState('')

    React.useEffect(() => {
      checkGroup(setUserIsAdmin, setUserIsDev);
    },[])

    React.useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);

      updateSize();
      return () => { 
        window.removeEventListener('resize', updateSize); 
      }
    }, []);


    
    
    React.useEffect(() => {
          onLoad();
          setUser(authData.attributes);
    },[authData])


    const [
        showFreeAccessForm, 
        showTopicIntrosTable,
        showQuestionsTable,
        showPuzzlesTable
    ] = React.useMemo(() => {

        const { actionInit, actionComplete, page } = componentsState;

        return[
            !actionInit && !actionComplete && page === 'free access',
            !actionInit && !actionComplete && page === 'topic descriptions',
            !actionInit && !actionComplete && page === 'questions',
            !actionInit && !actionComplete && page === 'puzzles'
        ]
    },[componentsState])


    return(
        <div className = { classes.root }>
            { showFreeAccessForm   && <FreeUserForm/> }
            { showQuestionsTable   && <QuestionsTable size = { size }/> }
            { showTopicIntrosTable && <TopicIntroTable size = { size }/> }
            { showPuzzlesTable     && <PuzzlesTable size = { size }/> }
            { componentsState.actionComplete && <SuccessPage/> }
            <NavBar userIsDev = { userIsDev === 'true' }/>
            { !!modalState.openModal && <Modal/> }
            { componentsState.form === 'createQuestion'   && <PracticeQuestionsForm/> }
            { componentsState.form === 'createTopicIntro' && <TopicIntroForm/> }
            { componentsState.form === 'createPuzzle'     && <PuzzleForm/> }
            {
                componentsState.actionInit
                &&
                <>
                  <div className = { classes.loading }>loading</div>
                  <Loader/>
                </>
            }
            {
              userIsAdmin !== 'true'
              &&
              <div className = { classes.root }>
                <Loader/>
              </div>
            }
        </div>
    )
}


const mapStateToProps = (reducer: reducerType) => {
    return {
        componentsState: reducer.components,
        modalState:      reducer.modal
    }
  };

  export default connect(mapStateToProps)(Root);
