import { store }                  from 'index'
import { submitQuestionFormType } from "actions/types";
import { componentsStateType }    from 'reducer/types';
import { SUBMIT_QUESTION_SAGA }   from 'actions';
import { 
   SET_COMPONENTS_STATE, 
   SET_ERROR
}                                 from 'actions/componentActions';

const submitQuestion = () => {

    const state              = store.getState();
    const sub                = state.user.sub;
    const questionsState     = state.questions;
    const componentsState    = state.components;
    const setComponentsState = (payload: componentsStateType) => store.dispatch(SET_COMPONENTS_STATE(payload));
    const setError           = (payload: string) => store.dispatch(SET_ERROR(payload));
    const submitForm         = (payload: submitQuestionFormType) => store.dispatch(SUBMIT_QUESTION_SAGA(payload));


    if ( questionsState.question.length === 0 ) {
       setError('you must enter a question');
       return;
    }

    setComponentsState({
       ...componentsState,
       error: '',
       form:  ''
    })

    submitForm({ newQuestion: questionsState, sub: sub });

 }

 export default submitQuestion;