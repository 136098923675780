import { call, take, put  } from 'redux-saga/effects';
import axios                from 'axios';
import { SET_TOPIC_INTROS } from '../actions';
import mockTopicIntros      from '../content/mockTopicIntros';

const endpoint = 'https://iseemath.co/adminApi/getTopicIntros.php';


function* getTopicIntros(): Generator<any,any,any> {
  while(true) {
    try {
     

        const userInput = yield take('GET_TOPIC_INTROS_SAGA');
       
        if (process.env.NODE_ENV === 'development') {

          yield put(SET_TOPIC_INTROS(mockTopicIntros))
        }


        if (process.env.NODE_ENV === 'production') {

            const res = yield call( axios.post, endpoint, { sub: userInput.sub });

          //  console.log('res         :   ',res)
           // console.log('res.data    :   ',JSON.stringify(res.data, null, 2))

            if ( res && res.data && res.data.result && res.data.result.length > 0) {
                yield put(SET_TOPIC_INTROS( res.data.result ))
            }

            else {
              yield put(SET_TOPIC_INTROS([]))
              console.log("can't get questions")
            }

            
        }
    }
    catch(err) {
      yield put(SET_TOPIC_INTROS([]))
      console.log('err in getUserLessons  :  '+err);
    }
    finally {}
  }
}


export default getTopicIntros;






