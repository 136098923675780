import { call, take, put } from 'redux-saga/effects';
import axios               from 'axios';
import {
    SUBMITION_INIT,
    SUBMITION_COMPLETED,
    SHOW_ERROR
  }                        from 'actions';

const dev = process.env.NODE_ENV === 'development';
const liveEndpoint    = 'https://iseemath.co/adminApi/suspendSubscription.php';
const sandBoxEndpoint = 'https://iseemath.co/adminApi/suspendSubscriptionSandbox.php';


function* suspendSubscription(): Generator<any,any,any> {
  while(true) {
    try {

        yield take('UNSUBSCRIBE_SAGA');

        const userInput = {
            userEmail:      'raphael.shalem@gmail.com',
            subscriptionID: 'I-CLJYADRE40AC',
            tester:         false
        }

        yield put(SUBMITION_INIT());

        if (dev) {
            yield put(SUBMITION_COMPLETED(true));
        }

        else {

            const { tester }  = userInput;
            const endpoint    = tester ? sandBoxEndpoint : liveEndpoint;
            const res         = yield call( axios.post, endpoint, userInput );

            console.log('res       :   ',res)
            console.log('res.data  :   ',res.data)

    
            if (res && res.data && res.data.success && res.data.success === 1 ) {
                yield put(SUBMITION_COMPLETED(true));
            }
            else {
                yield put(SHOW_ERROR());
            }
        } 
    }
    catch(err) {
      yield put(SHOW_ERROR());
      console.log('err  :  '+err);
    }
    finally {}
  }
}


export default suspendSubscription;


