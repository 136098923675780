import { store } from 'index'
import { 
    GET_PUZZLES_SAGA, 
    GET_QUESTIONS_SAGA, 
    GET_TOPIC_INTROS_SAGA 
} from 'actions';

const onLoad = () => {
    store.dispatch(GET_PUZZLES_SAGA());
    store.dispatch(GET_QUESTIONS_SAGA());
    store.dispatch(GET_TOPIC_INTROS_SAGA());
}

export default onLoad;

