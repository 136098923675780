import { store }                 from 'index'
import { deletequestion_type }   from "actions/types";
import { DELETE_QUESTIONS_SAGA } from 'actions';

const deleteQuestions = () => {

    const state               = store.getState();
    const deleteState         = state.delete;
    const questionsArr        = state.content.questions;
    const sub                 = state.user.sub;
    const deleteQuestionsSaga = (payload: deletequestion_type) => store.dispatch(DELETE_QUESTIONS_SAGA(payload));

    deleteQuestionsSaga({ sub: sub, selectedItems: deleteState.selectedItems, questionArr: questionsArr })
 }

 export default deleteQuestions;


