import { ActionTypes }      from 'actions/types';
import { contentStateType } from './types';

const initialState: contentStateType = {
   questions:   [],
   topicIntros: [],
   puzzles:     []
};

const contentReducer = (state = initialState, action: ActionTypes) => {

  switch (action.type) {


   //SET // // // // // // // // // // // // // // // // // //

    case 'SET_QUESTIONS':
    {
       return {
         ...state,
         questions: action.payload 
       }
    }

    case 'SET_TOPIC_INTROS':
    {
       return {
         ...state,
         topicIntros: action.payload 
       }
    }

    case 'SET_PUZZLES':
    {
       return {
         ...state,
         puzzles: action.payload 
       }
    }

    //UPDATE // // // // // // // // // // // // // // // // // //


    case 'UPDATE_QUESTIONS':
    {
       let newQ = state.questions;
       newQ.push(action.payload);
       return {
         ...state,
         questions: newQ
       }
    }


    case 'UPDATE_TOPIC_INTROS':
    {
       let newT = state.topicIntros;
       newT.push(action.payload);
       return {
         ...state,
         topicIntros: newT
       }
    }
   


    default: return state;
  }
};


export { contentReducer };
