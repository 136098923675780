import React                  from 'react';
import { connect }            from 'react-redux';
import { Dispatch }           from "redux";
import { reducerType }        from 'reducer';
import { ActionTypes }        from 'actions/types';
import resetStates            from 'functions/reset_states';
import submitQuestion         from 'functions/forms/submit_question';
import { colors }             from 'utils/colors';
import { makeStyles }         from '@material-ui/core/styles';
import { questionsStateType } from 'reducer/types';
import { SET_QUESTIONS_STATE } from 'actions/questionsActions';

const backendErr = "Data not uploaded dut to error";

const useStyles = makeStyles(() => ({
    root: {
        position:        'fixed',
        top:             0,
        height:          '100vh',
        width:           '100vw',
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
        backgroundColor: '#00000077'
    },
    form: {
        display:         'flex',
        flexFlow:        'column',
        border:          '1px solid #DDD',
        backgroundColor: '#FFF',
        padding:         10
    },
    error: {
       display:         'flex',
       alignItems:      'center',
       justifyContent:  'center',
       cursor:          'default',
       height:          40,
       fontSize:        18,
       color:           '#E00'
    },
    input: {
        width:     '45vw',
        height:    40,
        minWidth:  350,
        fontSize:  18,
        margin:    8
    },
    button: {
       width:           200,
       height:          45,
       margin:          8,
       display:         'flex',
       alignItems:      'center',
       justifyContent:  'center',
       color:           '#FFF',
       backgroundColor: colors.primary,
       fontWeight:      'bold',
       cursor:          'pointer',
       '&:hover': {
          opacity: 0.9
       }
    },
    xContainer: {
        height:          30,
        width:           '100%',
        display:         'flex',
        justifyContent:  'right',
    },
    x: {
        height:          30,
        width:           30,
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
        backgroundColor: colors.primary,
        color:           '#FFF',
        fontWeight:      'bold',
        cursor:          'pointer'

    }
}))


type PracticeQuestionFormType = {
    questionsState:    questionsStateType;
    error:             string;
    showError:         boolean;
    setQuestionsState: ( val: questionsStateType ) => void;
}

const PracticeQuestionForm: React.FC<PracticeQuestionFormType> = ({ 
    questionsState, 
    error, 
    showError, 
    setQuestionsState 
}) => {

    const classes = useStyles();
    

    const changeInputValue = (e: React.FormEvent) => {

        e.preventDefault();
    
        const target = e.target as HTMLInputElement;
    
        if (target.id === 'question')       { setQuestionsState({ ...questionsState, question:       target.value }) }
        if (target.id === 'question_type')  { setQuestionsState({ ...questionsState, question_type:  target.value }) }
        if (target.id === 'practice_set')   { setQuestionsState({ ...questionsState, practice_set:   target.value }) }
        if (target.id === 'answer_choices') { setQuestionsState({ ...questionsState, answer_choices: target.value }) }
        if (target.id === 'correct_answer') { setQuestionsState({ ...questionsState, correct_answer: target.value }) }
        if (target.id === 'image')          { setQuestionsState({ ...questionsState, image:          target.value }) }
    
    }

    return(
    <div className = { classes.root }>
        <div className = { classes.form }>
            <div className = { classes.xContainer }
            >
                <div 
                className = { classes.x }
                onClick   = { () => { resetStates('') }}
                >
                    x
                </div>
            </div>
            <div className = { classes.error }>
                { showError ? backendErr : error }
            </div>
            {
                Object.keys(questionsState).map((val) => {

                    if ( val === 'id' ) {
                        return(<div key = { '₪' }/>)
                    }
                    return(
                        <textarea 
                           key         = { val }
                           className   = { classes.input }
                           id          = { val }
                           placeholder = { val }
                           value       = { questionsState[`${ val }`] }
                           onChange    = { changeInputValue }
                           style       = {{
                               height : (val === 'question' || val === 'answer_choices') ? 90 : undefined
                           }}
                        />
                    )
                })
            }
            <div
               className = { classes.button }
               onClick   = { submitQuestion }
            >
                submit
            </div>
        </div>
    </div>
    )
}

const mapStateToProps = (reducer: reducerType) => {
   return {
       questionsState: reducer.questions,
       error:          reducer.components.error,
       showError:      reducer.components.showError
   }
 };

 const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
    setQuestionsState: (payload: questionsStateType) => dispatch(SET_QUESTIONS_STATE(payload)),
});

 export default connect(mapStateToProps, mapDispatchToProps)(PracticeQuestionForm);
