import { ActionTypes }        from 'actions/types';
import { questionsStateType } from './types';

export const initialQuestionsState: questionsStateType = {
    question:       '',
    question_type:  '',
    practice_set:   '',
    answer_choices: '',
    correct_answer: '',
    image:          '',
    id:             -1,
};

const questionsReducer = (state = initialQuestionsState, action: ActionTypes) => {

  switch (action.type) {


    case 'SET_QUESTIONS_STATE':
    {
       return {
         ...action.payload 
       }
    }

    default: return state;
  }
};


export { questionsReducer };
