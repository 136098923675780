import React              from 'react';
import { connect }        from 'react-redux';
import { Dispatch }       from "redux";
import { reducerType }    from 'reducer';
import { ActionTypes }    from 'actions/types';
import Buttons            from './Buttons';
import TextInput          from './TextInput';
import editQuestion       from 'functions/tables/edit_question';
import editTopicIntro     from 'functions/tables/edit_topic_intro';
import editPuzzle         from 'functions/tables/edit_puzzle';

import { 
    modalStateType, 
    puzzleStateType, 
    topicIntroStateType
}                         from 'types';

import { makeStyles }     from '@material-ui/core/styles';
import { questionsStateType } from 'reducer/types';
import { SET_QUESTIONS_STATE } from 'actions/questionsActions';
import { SET_TOPIC_INTRO_STATE } from 'actions/topicIntroActions';
import { SET_PUZZLE_STATE } from 'actions/puzzleActions';
import { SET_MODAL_STATE } from 'actions/modalActions';
import { initialModalState } from 'reducer/modalReducer';

var clsx = require('classnames')

const eArr = new Array(16).fill('e').map((val, ind) => { return(`${ val }${ ind + 1 }`) })
    
const puzzleVariants     = ['topic', 'puzzletype', 'puzzle_type_text', 'description_teacher', 'description_student', 'extra_url', 'page', 'img', 'id'].concat(eArr);
const topicIntroVariants = ['topic','sub_topic','youtube','img','teacher_text', 'student_text']
const questionsVariants  = ['question','question_type','practice_set','answer_choices','correct_answer','image']

const texts: { [key: string]: string; } = {
    'question':             'Edit question',
    'question_type':        'Edit question type',
    'practice_set':         'Edit practice_set type',
    'answer_choices':       'Edit answer choices',
    'correct_answer':       'Edit correct answers',
    'image':                'Edit image file name',
    'img':                  'Edit image file name',
    'topic':                'Edit topic',
    'sub_topic':            'Edit sub_topic',
    'youtube':              'Edit youtube',
    'teacher_text':         'Edit teacher description',
    'student_text':         'Edit student description',
    'description_teacher':  'Edit teacher description',
    'description_student':  'Edit student description',
    'puzzletype':           'Edit puzzletype',
    'puzzle_type_text':     'Edit puzzle_type_text',
    'extra_url':            'Edit extra_url',
    'page':                 'Edit page',
    'e1':                   'Edit e1',
    'e2':                   'Edit e2',
    'e3':                   'Edit e3',
    'e4':                   'Edit e4',
    'e5':                   'Edit e5',
    'e6':                   'Edit e6',
    'e7':                   'Edit e7',
    'e8':                   'Edit e8',
    'e9':                   'Edit e9',
    'e10':                  'Edit e10',
    'e11':                  'Edit e11',
    'e12':                  'Edit e12',
    'e13':                  'Edit e13',
    'e14':                  'Edit e14',
    'e15':                  'Edit e15',
    'e16':                  'Edit e16',
}




const useStyles = makeStyles(() => ({
    root: {
        position:        'fixed',
        top:             0,
        left:            0,
        minWidth:        '100vw',
        maxWidth:        '100vw',
        minHeight:       '100vh',
        maxHeight:       '100vh',
        backgroundColor: '#00000055',
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
    },
    container: {
        left:            0,
        top:             60,
        width:           600,
        overflowY:       'scroll',
        overflowX:       'hidden',
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'space-between',
        flexFlow:        'column',
        backgroundColor: '#FFF',
        transition:      'opacity 0.3s',
        '&::-webkit-scrollbar': {
          width: '0.5em',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: `#BBB`,
          borderRadius:    10
        }
    },
    title: {
       display:         'flex',
       alignItems:      'center',
       justifyContent:  'center',
       cursor:          'default',
       height:          40,
       fontSize:        18,
       marginBottom:    15,
       paddingTop:      30,

    },
    error: {
       display:         'flex',
       alignItems:      'center',
       justifyContent:  'center',
       cursor:          'default',
       height:          40,
       fontSize:        18,
       color:           '#E00'
    },
    small: {
        height: 300,
    },
    big: {
        height: 550,
    },
}))



type ModalPropsType = {
    page:              string;
    modalState:        modalStateType;
    questionsState:    questionsStateType;
    topicIntroState:   topicIntroStateType;
    puzzleState:       puzzleStateType;
    setQuestionsState: (val: questionsStateType) => void;
    setTopicIntroState:(val: topicIntroStateType) => void;
    setPuzzleState:    (val: puzzleStateType) => void;
    setModalState:     (val: modalStateType) => void;
}

const Modal: React.FC<ModalPropsType> = ({ 
    page,
    modalState, 
    questionsState,
    topicIntroState,
    puzzleState,
    setTopicIntroState,
    setQuestionsState,
    setPuzzleState,
    setModalState
}) => {

    const classes = useStyles();

    const [textInputState, setTextInputState] = React.useState(modalState.modalValue);

    const [variant, value] = React.useMemo(() => { 
        return[modalState.modalVariant, modalState.modalValue] 
    },[modalState])

    React.useEffect(() => {

        if ( page === 'questions' ) {
            if ( questionsState[`${ variant }`] !== textInputState ) {
                const newQuestionsState = {
                    ...questionsState
                }
    
                newQuestionsState[`${ variant }`] = textInputState;
                setQuestionsState(newQuestionsState);
            }
        }

        if ( page === 'topic descriptions' ) {
            if ( topicIntroState[`${ variant }`] !== textInputState ) {
                const newTopicIntroState = {
                    ...topicIntroState
                }
    
                newTopicIntroState[`${ variant }`] = textInputState;
                setTopicIntroState(newTopicIntroState);
            }
        }

        if ( page === 'puzzles' ) {
            if ( puzzleState[`${ variant }`] !== textInputState ) {
                const newpuzzleState = {
                    ...puzzleState
                }
    
                newpuzzleState[`${ variant }`] = textInputState;
                setPuzzleState(newpuzzleState);
            }
        }

        
        
    },[page, variant, questionsState, topicIntroState, textInputState, puzzleState, setQuestionsState, setTopicIntroState, setPuzzleState])


    const actions: { [key: string]: any } = React.useMemo(() => {

        return {
            editQuestionsTable: {
                text: !!texts[variant] ? `${ texts[variant] } for item ${ questionsState.id }` : '',
                func: editQuestion
            },
            editTopicIntroTable: {
                text: !!texts[variant] ? `${ texts[variant] } for item ${ topicIntroState.id }` : '',
                func: editTopicIntro
            },
            editPuzzlesTable: {
                text: !!texts[variant] ? `${ texts[variant] } for item ${ puzzleState.id }` : '',
                func: editPuzzle
            },
            def: {
                text: '',
                func: () => {}
            },
        }

    },[variant, questionsState, topicIntroState, puzzleState])


    const [showQuestionInput, showTopicIntroInput, showPuzzlesInput] = React.useMemo(() => {

        return [
            (page === 'questions'          && questionsVariants.includes(variant)),
            (page === 'topic descriptions' && topicIntroVariants.includes(variant)),
            (page === 'puzzles'            && puzzleVariants.includes(variant))
        ]
    },[variant, page])


    const action = React.useMemo(() => {

        if ( showQuestionInput ) {
            return actions.editQuestionsTable
        }
        if ( showTopicIntroInput ) {
            return actions.editTopicIntroTable
        }
        if ( showPuzzlesInput ) {
            return actions.editPuzzlesTable
        }

        return actions.def

    },[actions, showQuestionInput, showTopicIntroInput, showPuzzlesInput])


    const bigModal = React.useMemo(() => { 
        return (page === 'puzzles' || variant === 'question' || variant === 'description' || variant === 'answer_choices') 
    },[page, variant]);


    const handleClose = () => { setModalState(initialModalState) }


    return(
    <div className = { classes.root }>
        <div className = { clsx(classes.container, (bigModal ? classes.big : classes.small)) }>
            <div className = { classes.title }>
                   { !!action.text ? action.text : value }
            </div>
            {
                ( showQuestionInput || showTopicIntroInput || showPuzzlesInput ) &&
                <TextInput
                   variant  = { variant }
                   bigModal = { bigModal }
                   state    = { textInputState }
                   setState = { setTextInputState }
                />
            }
            <Buttons
               showAction  = { !!action.text }
               modalState  = { modalState } 
               handleClose = { handleClose }
               func        = { action.func }
            />
        </div>
    </div>
    )
}

const mapStateToProps = (reducer: reducerType) => {
   return {
       page:            reducer.components.page,
       modalState:      reducer.modal,
       questionsState:  reducer.questions,
       topicIntroState: reducer.topicIntros,
       puzzleState:     reducer.puzzles,
   }
 };

 const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
    setQuestionsState:  (payload: questionsStateType) => dispatch(SET_QUESTIONS_STATE(payload)),
    setTopicIntroState: (payload: topicIntroStateType) => dispatch(SET_TOPIC_INTRO_STATE(payload)),
    setPuzzleState:     (payload: puzzleStateType) => dispatch(SET_PUZZLE_STATE(payload)),
    setModalState:      (payload: modalStateType) => dispatch(SET_MODAL_STATE(payload)),
});

 export default connect(mapStateToProps, mapDispatchToProps)(Modal);
