import { call, take, put  }  from 'redux-saga/effects';
import axios                 from 'axios';
import { SET_QUESTIONS }     from '../actions';
import mockQuestions         from '../content/mockQuestions';

const endpoint = 'https://iseemath.co/adminApi/getQuestions.php';


function* getQuestion(): Generator<any,any,any> {
  while(true) {
    try {
     

        const userInput = yield take('GET_QUESTIONS_SAGA');
       
        if (process.env.NODE_ENV === 'development') {
          yield put(SET_QUESTIONS(mockQuestions))
        }


        if (process.env.NODE_ENV === 'production') {

            const res = yield call( axios.post, endpoint, { sub: userInput.sub });

           // console.log('res         :   ',res)
           // console.log('res.data    :   ',JSON.stringify(res.data, null, 2))

            if ( res && res.data && res.data.result && res.data.result.length > 0) {
                yield put(SET_QUESTIONS( res.data.result ))
            }

            else {
              yield put(SET_QUESTIONS([]))
              console.log("can't get questions")
            }

            
        }
    }
    catch(err) {
      yield put(SET_QUESTIONS([]))
      console.log('err in getUserLessons  :  '+err);
    }
    finally {}
  }
}


export default getQuestion;






