import { SET_USER_STATE } from 'actions/userActions';
import { store }  from 'index'


const setUser = (
    userAttributes: {
        sub:           string;
        email:         string;
        'custom:name': string;
    }
) => {

    store.dispatch(SET_USER_STATE({
        sub:       userAttributes.sub,
        userEmail: userAttributes.email,
        userName:  userAttributes['custom:name']
    }));
    
}

export default setUser;

