import React                     from 'react';
import { connect }               from 'react-redux';
import { Dispatch }              from "redux";
import { reducerType }           from 'reducer';
import { ActionTypes }           from 'actions/types';
import RightButtons              from './RightButtons';
import LeftButtons               from './LeftButtons';
import { colors }                from 'utils/colors';
import { 
   deleteStateType, 
   duplicationStateType 
}                                from 'types';
import { makeStyles }            from '@material-ui/core/styles';
import { DO_TEST_SAGA }          from 'actions';
import { 
    UNSUBSCRIBE_SAGA,
    REACTIVATE_SUBSCRIPTION_SAGA
}                                from 'actions/accountActions';


const useStyles = makeStyles(() => ({
    root: {
        position:        'fixed',
        top:             0,
        left:            0,
        width:           '100vw',
        height:          50,
        display:         'flex',
    },
    button: {
        maxHeight:       26,
        marginTop:       12,
        marginRight:     5,
        marginLeft:      5,
        paddingRight:    5,
        paddingLeft:     5,
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
        cursor:          'pointer',
        backgroundColor: '#334',
        color:           '#FFF'
    },
   title: {
      position:       'fixed',
      height:         26,
      top:            12,
      left:           '50%',
      transform:      'translate(-50%, 0%)',
      color:          '#FFF',
      display:        'flex',
      alignItems:     'center',
      justifyContent: 'center',
      fontWeight:     'bold'
   }
}))

type NavBarProps = {
    userIsDev:              boolean;
    page:                   string;
    deleteState:            deleteStateType;
    duplicateState:         duplicationStateType;
    doTest:                 () => void;
    unsubscribe:            () => void;
    reactivateSubscription: () => void;
}

const NavBar: React.FC<NavBarProps> = ({ 
    userIsDev, 
    page,
    deleteState,
    duplicateState,
    doTest,
    unsubscribe,
    reactivateSubscription
  }) => {


  const classes = useStyles();


  const [deleteMode, duplicateMode, normalMode] = React.useMemo(() => {
     return [
      deleteState.deleteMode,
      duplicateState.duplicateMode,
      (!deleteState.deleteMode && !duplicateState.duplicateMode)
     ]
  },[deleteState, duplicateState])


  const [rootStyle] = React.useMemo(() => {
   
   return[
      {
         backgroundColor: (deleteMode || duplicateMode) ? '#333' : colors.primary,
         justifyContent:  (deleteMode || duplicateMode) ? 'right' : 'left',
      }
     ]
  },[deleteMode, duplicateMode])
  
  
  return (
        <div 
           className = { classes.root }
           style     = { rootStyle }
        >
            { normalMode && <LeftButtons/> }
            {
              (page === 'questions' || page === 'topic descriptions' || page === 'puzzles') && 
              <RightButtons/>
            }
            {
                userIsDev
                &&
                <>
                   {/*  <div
                        className = { classes.button }
                        onClick   = { handleUploadData }
                    >
                         upload data
                    </div> */}
                    <div
                        className = { classes.button }
                        onClick   = { unsubscribe }
                     >
                         unsubscribe user
                    </div>
                    <div
                        className = { classes.button }
                        onClick   = { reactivateSubscription }
                     >
                         reactivate subscription
                    </div>
                    <div
                        className = { classes.button }
                        onClick   = { doTest }
                     >
                         do test
                    </div>
                </>
            } 
        </div>
    );
}



const mapStateToProps = (reducer: reducerType) => {
   return {
       page:           reducer.components.page,
       deleteState:    reducer.delete,
       duplicateState: reducer.duplicate,
   }
 };

 const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
    doTest:                 () => dispatch(DO_TEST_SAGA()),
    unsubscribe:            () => dispatch(UNSUBSCRIBE_SAGA()),
    reactivateSubscription: () => dispatch(REACTIVATE_SUBSCRIPTION_SAGA())
});

 export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
