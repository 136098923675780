
import { combineReducers }    from 'redux';
import { contentReducer }     from './contentReducer';
import { componentsReducer }  from './componentsReducer';
import { freeAccessReducer }  from './freeAccessReducer';
import { questionsReducer }   from './questionsReducer';
import { topicIntroReducer }  from './topicIntroReducer';
import { puzzleReducer }      from './puzzleReducer';
import { modalReducer }       from './modalReducer';
import { deleteReducer }      from './deleteReducer';
import { duplicationReducer } from './duplicationReducer';
import { userReducer }        from './userReducer';

const reducer = combineReducers({
  content:     contentReducer,
  components:  componentsReducer,
  freeAccess:  freeAccessReducer,
  questions:   questionsReducer,
  topicIntros: topicIntroReducer,
  puzzles:     puzzleReducer,
  modal:       modalReducer,
  delete:      deleteReducer,
  duplicate:   duplicationReducer,
  user:        userReducer,
  
})

export type reducerType = ReturnType<typeof reducer>;

export { reducer };
