import { call, take, put } from 'redux-saga/effects';
import axios               from 'axios';
import {
    SUBMITION_INIT,
    SUBMITION_COMPLETED,
    SHOW_ERROR
  }                        from 'actions';

const dev = process.env.NODE_ENV === 'development';
const endpoint = 'https://iseemath.co/api/createSubscription.php';


function* reactivateSubscription(): Generator<any,any,any> {
  while(true) {
    try {

        yield take('DO_TEST_SAGA');

        const userInput = {
            userEmail:        'raphael.shalem+3@gmail.com',
            subscriptionID:   'testing123123',
            subscriptionType: 'testPlan'
        }

        yield put(SUBMITION_INIT());

        if (dev) {

            yield put(SUBMITION_COMPLETED(true));
        }

        else {

            const res = yield call( axios.post, endpoint, userInput );

            console.log('res       :   ',res)
            console.log('res.data  :   ',res.data)

    
            if (res && res.data && res.data.success && res.data.success === 1 ) {
                yield put(SUBMITION_COMPLETED(true));
            }
            else {
                yield put(SHOW_ERROR());
            }
        } 
    }
    catch(err) {
      yield put(SHOW_ERROR());
      console.log('err  :  '+err);
    }
    finally {}
  }
}


export default reactivateSubscription;


