import { call, take, put } from 'redux-saga/effects';
import axios               from 'axios';
import {
  SUBMITION_INIT,
  SUBMITION_COMPLETED,
  SHOW_ERROR,
  SET_PUZZLES
}                          from '../actions';

const endpoint = 'https://iseemath.co/adminApi/deletePuzzles.php';

const updatePuzzlesArr = (arr: {[key: string]: any;}[], selectedItems: number[]) => {

  let newArr = [] as {[key: string]: any;}[];

  for ( let i = 0; i < arr.length; i++ ) {
    if ( !selectedItems.includes(arr[i].id) ) {
      newArr.push(arr[i])
    }
  }
  return newArr;
}


function* deletePuzzles(): Generator<any,any,any> {
  while(true) {
    try {

        const userInput = yield take('DELETE_PUZZLES_SAGA');

        yield put(SUBMITION_INIT());

        const { sub, puzzlesArr, selectedItems } = userInput.payload;

        if (process.env.NODE_ENV === 'development') {
            console.log('userInput : ',userInput)

           const updatedPuzzlesArr = yield(updatePuzzlesArr(puzzlesArr, selectedItems))
           yield put(SET_PUZZLES(updatedPuzzlesArr));
           yield put(SUBMITION_COMPLETED(false));
        }

        if (process.env.NODE_ENV === 'production') {

            const obj = {
              sub:           sub,
              selectedItems: selectedItems
            }

            const res = yield call( axios.post, endpoint, obj );

            console.log('res       :  ',res);
            console.log('res.data  :  ',res.data);
                   
            if ( !!res && !!res.data && !!res.data.success && res.data.success === 1 ) {

              const updatedPuzzlesArr = yield(updatePuzzlesArr(puzzlesArr, selectedItems))
              yield put(SET_PUZZLES(updatedPuzzlesArr));
              yield put(SUBMITION_COMPLETED(false));
            }
            else {
              yield put(SHOW_ERROR());
            }
        }
    }
    catch(err) {
      yield put(SHOW_ERROR());
      console.log('err  :  '+err);

    }
    finally {}
  }
}


export default deletePuzzles;
