import React                    from 'react';
import { colors }               from '../../utils/colors';
import { modalStateType }       from '../../types';
import { makeStyles }           from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    buttonContainer: {
        width:           '100%',
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
        flexFlow:        'row',
    },
    button: {
       width:           200,
       height:          45,
       margin:          20,
       display:         'flex',
       alignItems:      'center',
       justifyContent:  'center',
       color:           '#FFF',
       backgroundColor: colors.primary,
       fontWeight:      'bold',
       cursor:          'pointer',
       '&:hover': {
          opacity: 0.9
       }
    }
}))


type ButtonsPropsType = {
    showAction:  boolean;
    modalState:  modalStateType;
    handleClose: () => void;
    func:        () => void;
}

const Buttons: React.FC<ButtonsPropsType> = ({ showAction, modalState, handleClose, func }) => {

    const classes = useStyles();

    const handleSubmit = () => {
        func();
        handleClose();
    }

    return(
        <div className = { classes.buttonContainer }>
            {
                showAction
                &&
                <div
                   className = { classes.button }
                   onClick   = { handleSubmit }
                >
                    submit
                </div>
            }
            <div
               className = { classes.button }
               onClick   = { handleClose }
            >
                close
            </div>
        </div>
    )
}

export default Buttons;