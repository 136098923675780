import React            from 'react';
import SignInForm       from './signIn';
import Loader           from '../Loader';
import { colors }       from '../../utils/colors';
import { makeStyles }   from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    root : {
        position:        'fixed',
        left:            0,
        top:             0,
        height:          '100vh',
        width:           '100vw',
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
        backgroundColor: 'rgba(0,0,0,0.3)',
        transition:      'opacity 0.5s',
        transform:       'all 0.4s',
        overflowY:       'scroll',
        overflowX:       'hidden',
        '&::-webkit-scrollbar': {
          width: '0.5em'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: `#FFF`,
          borderRadius:    10
        }
    },
    authenticator: {
        marginLeft:      10,
        display:         'flex',
        justifyContent:  'space-evenly',
        flexFlow:        'column',
        backgroundColor: '#FFF',
        border:          '1px solid #DDD',
        borderRadius:    '0.25em',
        boxShadow:       '3px 3px 3px rgba(0,0,0,0.05)'
    },
    iconContainer: {
      height:          37,
      width:           '100%',
      display:         'flex',
      alignItems:      'flex-end',
      justifyContent:  'flex-end',
    },
    closeIcon: {
        color:       colors.blue,
        cursor:      'pointer',
        marginRight: 12,
        fontSize:    32,
        '&:hover': {
            opacity: 0.5
        }
    },
    loaderContainer: {
      position:        'absolute',
      backgroundColor: '#FFF',
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
    }
}));
/* 

type AuthenticatorProps = {
    createNewUser: (email: string, name: string) => void;
} */

const Authenticator = () => {
  
    const classes = useStyles();


    const [size, setSize] = React.useState([0,0]);

    React.useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);


    const [displayForm, setDisplayForm]         = React.useState('sign_in'); //sign_in
    const [showLoader, setShowLoader]           = React.useState(false);

    const [email, setEmail]                     = React.useState('');
    const [password, setPassword]               = React.useState('');
    const [error, setError]                     = React.useState('');
  
    
    React.useEffect(() => {
      setError('')
    },[displayForm, setError])


   /*  React.useEffect(() => {

      //   const signin = async() => {
       //    await Auth.signIn(email, password);
       //    window.location.reload(); 
       // } 
    
        if ( newUserCreated === 'true' ) {
          setShowLoader(false); 
          setShowAuthForm(false)
          setShowPayForm(true)
         // signin();
        }
    
        if ( newUserCreated === 'false' ) {
          setShowLoader(false); 
          setError('Something went wrong. Please try again later');
        }
    
    },[newUserCreated, email, password, setShowAuthForm, setShowPayForm, setError, setShowLoade])


     */

    const handleCreateUser = () => {
      console.log('creating user')
     // createNewUser(email, name); 
    }
  
  
    const formStyle = React.useMemo(() => {
         return {
            width:  Math.min(700, size[0] - 20),
            height: Math.min(600, size[1]),
         }
    },[size])
  
  
    return (
      <div className = { classes.root }>
          <div 
             className = { classes.authenticator }
             style     = { formStyle }
          >
            {
            displayForm === 'sign_in'
            &&
              <SignInForm
                size             = { size }
                email            = { email }
                password         = { password }
                error            = { error }
                setError         = { setError }
                setShowLoader    = { setShowLoader }
                setEmail         = { setEmail }
                setPassword      = { setPassword }
                setDisplayForm   = { setDisplayForm }
                handleCreateUser = { handleCreateUser }  
              />
            }
            {
              showLoader && 
              <div 
                 className = { classes.loaderContainer }
                 style     = { formStyle }
              >
                 <Loader variant = { 'blue' }/>
              </div>
            }
          </div>
      </div>
    )
};

export default Authenticator;