import React                       from 'react';
import { connect }                 from 'react-redux';
import { Dispatch }                from "redux";
import { reducerType }             from 'reducer';
import { ActionTypes }             from 'actions/types';
import resetStates                 from 'functions/reset_states';
import deleteQuestions             from 'functions/tables/delete_questions';
import deletePuzzles               from 'functions/tables/delete_puzzles';
import deleteTopicIntros           from 'functions/tables/delete_topic_intros';
import { 
   deleteStateType, 
   duplicationStateType }          from 'types';
import { makeStyles }              from '@material-ui/core/styles';
import { initialDeleteState }      from 'reducer/deleteReducer';
import { SET_DELETE_STATE }        from 'actions/deleteActions';
import { componentsStateType }     from 'reducer/types';
import { SET_DUPLICATION_STATE }   from 'actions/duplicationActions';
import { initialDuplicationState } from 'reducer/duplicationReducer';


const useStyles = makeStyles(() => ({
    root: {
        flex:            1,
        height:          50,
        display:         'flex',
        justifyContent:  'right',
    },
    button: {
      marginRight:     15,
      height:          26,
      marginTop:       12,
      minHeight:       25,
      paddingRight:    10,
      paddingLeft:     10,
      display:         'flex',
      alignItems:      'center',
      cursor:          'pointer',
      backgroundColor: '#FFF'
   },
   title: {
      position:       'fixed',
      height:         26,
      top:            12,
      left:           '50%',
      transform:      'translate(-50%, 0%)',
      color:          '#FFF',
      display:        'flex',
      alignItems:     'center',
      justifyContent: 'center',
      fontWeight:     'bold'
   },
    item: {
        height:          40,
        width:           '100%',
        display:         'flex',
        alignItems:      'center',
        textAlign:       'left',
        paddingLeft:     5,
        cursor:          'pointer',
        backgroundColor: '#FFF',
        '&:hover': {
          backgroundColor: '#E6E6EA'
        }
    }
}))

type RightButtonsProps = {
    componentsState:   componentsStateType;
    deleteState:       deleteStateType;
    duplicateState:    duplicationStateType;
    setDeleteState:    (payload: deleteStateType ) => void;
    setDuplicateState: (payload: duplicationStateType) => void
}

const RightButtons: React.FC<RightButtonsProps> = ({ 
    componentsState,
    deleteState,
    duplicateState,
    setDeleteState,
    setDuplicateState
  }) => {


  const classes = useStyles();

  const handleSetForm = () => {

     const { page } = componentsState;

     let form = page === 'questions' ? 'createQuestion' :
                page === 'topic descriptions' ? 'createTopicIntro' :
                page === 'puzzles' ? 'createPuzzle' :
                ''

      resetStates(form)
  }


  const handleDelete = () => {
      if ( !deleteState.selectedItems ) {
         return;
      }
      if ( componentsState.page === 'questions' ) {
         deleteQuestions()
      }
      if ( componentsState.page === 'topic descriptions' ) {
         deleteTopicIntros()
      } 
      if ( componentsState.page === 'puzzles' ) {
         deletePuzzles()
      } 
   }


   const [deleteMode, duplicateMode, normalMode] = React.useMemo(() => {

      const { deleteMode }    = deleteState;
      const { duplicateMode } = duplicateState;

      return[
         deleteMode,
         duplicateMode,
         (!deleteMode && !duplicateMode)
      ]
   },[deleteState, duplicateState])


   const [deleteButtonStyle] = React.useMemo(() => {

      const deleteLen = !!deleteState.selectedItems.length;

      return[
         {
            opacity:         deleteLen ? 1 : 0.4,
            cursor:          deleteLen ? 'pointer' : 'default',
            backgroundColor: deleteLen ? '#F99' : '#FFF'
         }

      ]
   },[deleteState])
  

  
  return (
            <div className = { classes.root }>
                  {
                    normalMode &&
                    <>
                        <div 
                           className = { classes.button }
                           onClick   = { handleSetForm }
                        >
                           create new
                        </div>
                        <div 
                           className = { classes.button }
                           onClick   = { () => { setDuplicateState({ ...duplicateState, duplicateMode: true }) } }
                        >
                           duplicate
                        </div>
                        {
                        normalMode && 
                        <div 
                           className = { classes.button }
                           onClick   = { () => { setDeleteState({ ...deleteState, deleteMode: true }) } }
                        >
                           delete
                        </div>
                        }
                    </>
                  }
                  {
                     deleteMode &&
                     <>
                        <div className = { classes.title }>
                           select items to delete
                        </div>
                        <div 
                           className = { classes.button }
                           onClick   = { handleDelete }
                           style     = { deleteButtonStyle }
                        >
                           delete selected items
                        </div>
                      
                        <div 
                           className = { classes.button }
                           onClick = { () => { setDeleteState( initialDeleteState ) }}
                        >
                           cancel
                        </div>
                     </>
                  }
                  {
                     duplicateMode &&
                     <>
                        <div className = { classes.title }>
                           select item to duplicate
                        </div>
                        {/* <div 
                           className = { classes.button }
                           onClick   = { duplicate }
                           style     = { duplicateButtonStyle }
                        >
                           duplicate selected items
                        </div>
                       */}
                        <div 
                           className = { classes.button }
                           onClick = { () => { setDuplicateState( initialDuplicationState ) }}
                        >
                           cancel
                        </div>
                     </>
                  }
        </div>
    );
}

const mapStateToProps = (reducer: reducerType) => {
   return {
       componentsState: reducer.components,
       deleteState:     reducer.delete,
       duplicateState:  reducer.duplicate
   }
 };

 const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
   setDeleteState:    (payload: deleteStateType) => dispatch(SET_DELETE_STATE(payload)),
   setDuplicateState: (payload: duplicationStateType) => dispatch(SET_DUPLICATION_STATE(payload)),
});

 export default connect(mapStateToProps, mapDispatchToProps)(RightButtons);




