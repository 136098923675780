import { store }                   from 'index'
import { SET_QUESTIONS_STATE }     from 'actions/questionsActions';
import { SET_TOPIC_INTRO_STATE }   from 'actions/topicIntroActions';
import { SET_PUZZLE_STATE }        from 'actions/puzzleActions';
import { SET_FORM }                from 'actions/componentActions';
import { SET_DUPLICATION_STATE }   from 'actions/duplicationActions';
import { initialDuplicationState } from 'reducer/duplicationReducer';

const duplicate = (
    form: string,
    obj:  { [key: string]: any }
) => {


    if ( form === 'createPuzzle' ) {
        store.dispatch(SET_PUZZLE_STATE(obj));
    }

    if ( form === 'createQuestion' ) {
        store.dispatch(SET_QUESTIONS_STATE(obj));
    }

    if ( form === 'createTopicIntro' ) {
        store.dispatch(SET_TOPIC_INTRO_STATE(obj));
    }

    store.dispatch(SET_FORM(form));
    store.dispatch(SET_DUPLICATION_STATE(initialDuplicationState));

}

export default duplicate;