import { store }               from 'index'
import { deletePuzzlestype }   from "actions/types";
import { DELETE_PUZZLES_SAGA } from 'actions';

const deletePuzzles = () => {

    const state               = store.getState();
    const deleteState         = state.delete;
    const puzzlesArr          = state.content.puzzles;
    const sub                 = state.user.sub;
    const deletePuzzlesSaga   = (payload: deletePuzzlestype) => store.dispatch(DELETE_PUZZLES_SAGA(payload));

    deletePuzzlesSaga({ sub: sub, selectedItems: deleteState.selectedItems, puzzlesArr: puzzlesArr })
 }

 export default deletePuzzles;


