import React                  from 'react';
import { 
  deleteStateType, 
  duplicationStateType 
}                             from 'types';
import { questionsStateType } from 'reducer/types';
import duplicate              from 'functions/tables/duplicate';
import openModal              from 'functions/modal/open_modal';
import { makeStyles }         from '@material-ui/core/styles';


var clsx = require('classnames')


const useStyles = makeStyles(() => ({
    item: {
        flex:            1,
        height:          '100%',
        display:         'flex',
        alignItems:      'center',
        borderLeft:      '1px solid #999',
        borderTop:       '1px solid #999',
        flexWrap:        'wrap',
        overflowX:       'auto',
        transition:      'opacity 0.3s',
      //  backgroundColor: '#FAFAFA',
        paddingLeft:     8,
        '&::-webkit-scrollbar': {
          height: '0.5em',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: `#BBB`,
          borderRadius:    10
        },
        '&:hover': {
            backgroundColor: '#EEF'
        }
    },
    note: {
        flex:         1,
        paddingRight: 8
    },
}))



type ItemPropsType = {
    variant:            string;
    index:              number;
    marked:             boolean;
    obj:                questionsStateType;
    widths:             { [key: string]: number };
    deleteState:        deleteStateType;
    duplicateState:     duplicationStateType;
    setQuestionsState:  ( val: questionsStateType ) => void;
    setDeleteState:     ( val: deleteStateType ) => void;
}



const Item: React.FC<ItemPropsType> = ({ 
     obj,
     marked,
     index,
     variant,
     widths,
     deleteState,
     duplicateState,
     setQuestionsState,
     setDeleteState,
    }) => {

    const classes = useStyles();


    const value = React.useMemo(() => {
        return (!!obj[variant] || obj[variant] === 0) ? obj[variant] : ''
    },[obj, variant])


    const handleClick = () => {
  
        if ( deleteState.deleteMode ) {

           let newArr = [...deleteState.selectedItems]
           const id   = obj['id'];

           if ( marked ) {
             newArr.splice(newArr.indexOf(id), 1)
           }
           else {
             newArr.push(id)
           }

           setDeleteState({
               ...deleteState,
               selectedItems: newArr
           })
        }

        else if ( duplicateState.duplicateMode ) {
          duplicate('createQuestion', obj)
        } 
        
        else {
          
            setQuestionsState(obj);
            openModal(variant, `${ value }`);
        }
    }

    


    return(
        <div 
           className = { (variant === 'comments' || variant === 'notes') ? clsx( classes.item, classes.note ) : classes.item } 
           onClick   = { handleClick }
           style     = {{ 
            minWidth:        !!widths[variant] ? widths[variant] : 150,
            maxWidth:        variant === 'id' ? 30 : undefined,
            backgroundColor: obj.expired ? '#DDD' : undefined,
            cursor:          'pointer'
          }}
        >
           { value }
        </div>
    )
}



export default Item;