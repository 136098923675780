import { call, take, put } from 'redux-saga/effects';
import axios               from 'axios';
import {
  SUBMITION_INIT,
  SUBMITION_COMPLETED,
  SHOW_ERROR
}                          from '../actions';

const endpoint = 'https://iseemath.co/adminApi/createFreeUsers.php';

function* submitForm(): Generator<any,any,any> {
  while(true) {
    try {

        const userInput = yield take('SUBMIT_FREE_USER_FORM_SAGA');

        yield put(SUBMITION_INIT());

        const { emails, comments, sub } = userInput.payload;
        const arr = emails.split(',');
        let counter = 0;


        if (process.env.NODE_ENV === 'development') {
          console.log('emails    :  ',emails)
          console.log('comments  :  ',comments)
          console.log('sub       :  ',sub)

           yield put(SUBMITION_COMPLETED(true));
        }

        if (process.env.NODE_ENV === 'production') {

            for ( let i = 0; i < arr.length; i++ ) {

                    console.log(`${ i } / ${ arr.length }`)

                    const obj = {
                      email:    arr[i],
                      comments: comments.length ? comments : ' ',
                      sub:      sub
                    }
                  
                    const res = yield call( axios.post, endpoint, obj );

                    console.log('res       :  ',res);
                    console.log('res.data  :  ',res.data);
          
                    if (!res || !res.data || (res && res.data && res.data.success === 0 )) {
                      counter = -1;
                      break;
                    }

                    counter++;
            }

            if ( counter === arr.length ) {
              yield put(SUBMITION_COMPLETED(true));
            }

            if ( counter === -1 ) {
              yield put(SHOW_ERROR());
            }
        }
    }
    catch(err) {
      yield put(SHOW_ERROR());
      console.log('err  :  '+err);

    }
    finally {}
  }
}


export default submitForm;
