import { ActionTypes }         from 'actions/types';
import { componentsStateType } from './types';

export const initialComponentsState: componentsStateType = {
   page:           'free access',
   form:           '',
   error:          '',
   actionInit:     false,
   actionComplete: false,
   showError:      false,
};

const componentsReducer = (state = initialComponentsState, action: ActionTypes) => {

  switch (action.type) {


   

    
    case 'SET_COMPONENTS_STATE':
    {
       return {
         ...state,
         ...action.payload
       }
    }


    case 'SUBMITION_INIT':
    {
       return {
         ...state,
         actionInit:     true,
         form:           '',
         error:          '',
         actionComplete: false,
         showError:      false,
       }
    }


    case 'SUBMITION_COMPLETED':
    {
       return {
         ...state,
         actionInit:     false,
         actionComplete: action.payload
       }
    }


    case 'SHOW_ERROR':
    {
       return {
          ...state,
          showError:      true,
          actionInit:     false,
          actionComplete: false,
       }
    }


    case 'SET_PAGE':
    {
       return {
          ...state,
          page: action.payload
       }
    }

    case 'SET_FORM':
    {
       return {
          ...state,
          form: action.payload
       }
    }

    case 'SET_ERROR':
    {
       return {
          ...state,
          error: action.payload
       }
    }


/* 
    case 'RESET':
    {
       return { ...initialState }
    }
 */

    default: return state;
  }
};


export { componentsReducer };
