import React                   from 'react';
import { connect }             from 'react-redux';
import { reducerType }         from 'reducer';
import { colors }              from 'utils/colors';
import resetStates             from 'functions/reset_states';
import { makeStyles }          from '@material-ui/core/styles';


const buttons = [
  {
    title:     'free access',
    variant:   'button',
    menuItems: []
  },
  {
    title:     'content',
    variant:   'dropDown',
    menuItems: ['questions', 'topic descriptions', 'puzzles' ]
  }
];

const useStyles = makeStyles(() => ({
    selector: {
        marginLeft: 15,
        height:     26,
        marginTop:  12,
    },
    button: {
        minHeight:      25,
        paddingRight:   10,
        paddingLeft:    10,
        display:        'flex',
        alignItems:     'center',
        justifyContent: 'center',
        cursor:         'pointer',
    },
    menu: {
        backgroundColor: '#FFF',
        overflowX:       'hidden',
        overflowY:       'hidden',
        boxShadow:       '0px 4px 10px rgba(0,0,0,0.15)',
        cursor:          'pointer',
        transition:      'height 0.1s'
      },
    item: {
        height:          40,
        width:           '100%',
        display:         'flex',
        alignItems:      'center',
        textAlign:       'left',
        paddingLeft:     5,
        cursor:          'pointer',
        backgroundColor: '#FFF',
        '&:hover': {
          backgroundColor: '#E6E6EA'
        }
    }
}))

type LeftButtonsProps = {
    page: string;
}

const LeftButtons: React.FC<LeftButtonsProps> = ({ page }) => {


  const classes = useStyles();

  const [dropDown, setDropDown]         = React.useState('');
  const [showDropDown, setShowDropDown] = React.useState(false);
  

  const handleClick = (val: { [key: string]: any }) => {

      if ( val.variant === 'button' ) {
         setShowDropDown(false)
         resetStates('current', val.title)
      }
      if ( val.variant === 'dropDown' ) {
        setDropDown(val.title)
        setShowDropDown(true)
     }
      
  }

  
  
  return (
        <>
            {
                buttons.map((val) => {
                    return(
                      <div
                         key       = { `${ val.title }_` }
                         className = { classes.selector }
                      >
                            <div 
                               className = { classes.button }
                               onClick   = { () => { handleClick(val) }}
                               style     = {{
                                 backgroundColor: (page === val.title || val.menuItems.includes(page)) ? colors.brightText : '#FFF',
                               }}
                            >
                               { val.title }
                            </div>
                            {
                                !!val.menuItems.length && 
                                <div 
                                   className = { classes.menu }
                                   style     = {{
                                      height:  (!showDropDown || dropDown !== val.title) ? 0 : ((40 * (val.menuItems.length)))
                                   }} 
                                >
                                  {
                                     val.menuItems.map((menuItem) => {
                                       return(
                                          <div 
                                             key       = { `${ menuItem }{}` }
                                             className = { classes.item }
                                             onClick   = { () => { handleClick({ title: menuItem, variant: 'button' }) }}
                                             style     = {{
                                                backgroundColor: page === menuItem ? colors.brightText : undefined,
                                             }}
                                          >
                                             { menuItem }
                                          </div> 
                                       )
                                     })
                                  }
                                </div>         
                            }
                      </div>
                    )
                })
            }
        </>
    );
}



const mapStateToProps = (reducer: reducerType) => {
   return {
       page: reducer.components.page
   }
 };


 export default connect(mapStateToProps)(LeftButtons);
