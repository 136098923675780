import { call, take, put  }  from 'redux-saga/effects';
import axios                 from 'axios';
import { SET_PUZZLES }       from '../actions';
import mockPuzzles           from '../content/mockPuzzles';

const endpoint = 'https://iseemath.co/adminApi/getPuzzles.php';


function* getPuzzles(): Generator<any,any,any> {
  while(true) {
    try {
     

        const userInput = yield take('GET_PUZZLES_SAGA');
       
        if (process.env.NODE_ENV === 'development') {
          yield put(SET_PUZZLES(mockPuzzles))
        }


        if (process.env.NODE_ENV === 'production') {

            const res = yield call( axios.post, endpoint, { sub: userInput.sub });

           // console.log('res         :   ',res)
           // console.log('res.data    :   ',JSON.stringify(res.data, null, 2))

            if ( res && res.data && res.data.result && res.data.result.length > 0) {
                yield put(SET_PUZZLES( res.data.result ))
            }

            else {
              yield put(SET_PUZZLES([]))
              console.log("can't get puzzles")
            }

            
        }
    }
    catch(err) {
      yield put(SET_PUZZLES([]))
      console.log('err in getPuzzles  :  '+err);
    }
    finally {}
  }
}


export default getPuzzles;






