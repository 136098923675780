import { Auth } from 'aws-amplify';

const goAway = () => { 
    localStorage.clear();
    window.location.assign('https://iseemath.co'); 
}

type setState = (val: string) => void;

const checkGroup = async(setUserIsAdmin: setState, setUserIsDev: setState ) => {

    try {      
        const user = await Auth.currentAuthenticatedUser();

        let userIsAdmin = 'false';
        let userIsDev   = 'false';

        const arr = user.signInUserSession.accessToken.payload["cognito:groups"];

        if ( arr && arr.includes('admins')) {
            userIsAdmin = 'true'
        }
        if ( arr && arr.includes('raphael')) {
            userIsDev = 'true'
        }

        setUserIsAdmin(userIsAdmin);
        setUserIsDev(userIsDev);

        if ( userIsAdmin === 'false' ) {
            goAway();
        }

    }
    catch(err) {
        goAway();
    }
    finally {
    }

}

export default checkGroup;
