import { call, take, put } from 'redux-saga/effects';
import axios               from 'axios';
import {
  SUBMITION_INIT,
  SUBMITION_COMPLETED,
  SHOW_ERROR,
  SET_PUZZLES
}                          from '../actions';

const endpoint = 'https://iseemath.co/adminApi/editPuzzles.php';

const updatePuzzlesArr = (arr: {[key: string]: any;}[], newPuzzle: {[key: string]: any;}) => {

    for ( let i = 0; i < arr.length; i++ ) {
      if ( arr[i].id === newPuzzle.id ) {
        arr[i] = newPuzzle;
        break;
      }
    }
    return arr;
}

function* editPuzzles(): Generator<any,any,any> {
  while(true) {
    try {

        const userInput = yield take('EDIT_PUZZLES_SAGA');

        yield put(SUBMITION_INIT());

        const { sub, puzzlesArr, puzzleState } = userInput.payload;

        console.log('puzzleState : ',puzzleState)


        if (process.env.NODE_ENV === 'development') {

            console.log('userInput : ',userInput)

           const updatedPuzzlesArr = yield(updatePuzzlesArr(puzzlesArr, puzzleState))
           yield put(SET_PUZZLES(updatedPuzzlesArr));
           yield put(SUBMITION_COMPLETED(false));

           
        }

        if (process.env.NODE_ENV === 'production') {

            const obj = {
              ...puzzleState,
              sub: sub
            }

            console.log(typeof obj.id)

            const res = yield call( axios.post, endpoint, obj );

            console.log('res       :  ',res);
            console.log('res.data  :  ',res.data);
                   

            if ( !!res && !!res.data && !!res.data.success && res.data.success === 1 ) {
               const updatedPuzzlesArr = yield(updatePuzzlesArr(puzzlesArr, puzzleState))
               yield put(SET_PUZZLES(updatedPuzzlesArr));
               yield put(SUBMITION_COMPLETED(false));
            }
            else {
              yield put(SHOW_ERROR());
            }
        }
    }
    catch(err) {
      yield put(SHOW_ERROR());
      console.log('err  :  '+err);

    }
    finally {}
  }
}


export default editPuzzles;
