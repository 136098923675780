import { ActionTypes } from 'actions/types';

export const initialModalState = {
    modalVariant: '',
    modalValue:   '',
    openModal:    false,
};

const modalReducer = (state = initialModalState, action: ActionTypes) => {

  switch (action.type) {


    case 'SET_MODAL_STATE':
    {
       return {
         ...action.payload 
       }
    }

    default: return state;
  }
};


export { modalReducer };
