import { call, take }     from 'redux-saga/effects';
import axios              from 'axios';
import arr                from '../content/newSchools2Final';

const endpoint = 'https://www.yisumatica.org.il/api/createNewSchools.php';


function* uploadData(): Generator<any,any,any> {
  while(true) {
    try {

            /* const userInput =  */yield take('UPLOAD_DATA_SAGA');

             for ( let i = 0; i < arr.length; i++ ) {

                  console.log(`${ i } / ${ arr.length }`)
                  const obj = {
                      semel_mosad:          arr[i].semel_mosad,
                      school_name:          arr[i].school_name,
                      grade:                arr[i].grade,
                      contact_person:       !!arr[i].contact_person ? arr[i].contact_person : '',
                      contact_person_phone: !!arr[i].contact_person_phone ? arr[i].contact_person_phone : '',
                      contact_person_email: !!arr[i].contact_person_email ? arr[i].contact_person_email : '',
                  }
  
                  const res = yield call( axios.post, endpoint, obj );
                  console.log('res  :  ',res)

                
            } 



    }
    catch(err) {
      console.log('err  :  '+err);
    }
    finally {}
  }
}


export default uploadData;
