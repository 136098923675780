import { ActionTypes }         from 'actions/types';
import { freeAccessStateType } from './types';

export const initialFreeAccessState: freeAccessStateType = {
    emails:   '',
    comments: ''
};

const freeAccessReducer = (state = initialFreeAccessState, action: ActionTypes) => {

  switch (action.type) {


    case 'SET_FREE_ACCESS_STATE':
    {
       return {
         ...action.payload 
       }
    }

    default: return state;
  }
};


export { freeAccessReducer };
