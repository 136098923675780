import { call, take, put } from 'redux-saga/effects';
import axios               from 'axios';
import {
  SUBMITION_INIT,
  SUBMITION_COMPLETED,
  SHOW_ERROR,
  SET_QUESTIONS
}                          from '../actions';

const endpoint = 'https://iseemath.co/adminApi/deleteQuestions.php';

const updateQuestionArr = (arr: {[key: string]: any;}[], selectedItems: number[]) => {

  let newArr = [] as {[key: string]: any;}[];

  for ( let i = 0; i < arr.length; i++ ) {
    if ( !selectedItems.includes(arr[i].id) ) {
      newArr.push(arr[i])
    }
  }
  return newArr;
}


function* deleteQuestions(): Generator<any,any,any> {
  while(true) {
    try {

        const userInput = yield take('DELETE_QUESTIONS_SAGA');

        console.log(userInput)
        
        yield put(SUBMITION_INIT());

        const { sub, questionArr, selectedItems } = userInput.payload;

        if (process.env.NODE_ENV === 'development') {

           const updatedQuestionArr = yield(updateQuestionArr(questionArr, selectedItems))
           yield put(SET_QUESTIONS(updatedQuestionArr));
           yield put(SUBMITION_COMPLETED(false));
        }

        if (process.env.NODE_ENV === 'production') {

            const obj = {
              sub:           sub,
              selectedItems: selectedItems
            }

            const res = yield call( axios.post, endpoint, obj );

            console.log('res       :  ',res);
            console.log('res.data  :  ',res.data);
                   
            if ( !!res && !!res.data && !!res.data.success && res.data.success === 1 ) {

              const updatedQuestionArr = yield(updateQuestionArr(questionArr, selectedItems))
              yield put(SET_QUESTIONS(updatedQuestionArr));
              yield put(SUBMITION_COMPLETED(false));
            }
            else {
              yield put(SHOW_ERROR());
            }
        }
    }
    catch(err) {
      yield put(SHOW_ERROR());
      console.log('err  :  '+err);

    }
    finally {}
  }
}


export default deleteQuestions;
