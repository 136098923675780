import { ActionTypes } from 'actions/types';

export const initialDeleteState = {
    deleteMode:    false,
    selectedItems: [] as number[]
};

const deleteReducer = (state = initialDeleteState, action: ActionTypes) => {

  switch (action.type) {


    case 'SET_DELETE_STATE':
    {
       return {
         ...action.payload 
       }
    }

    default: return state;
  }
};


export { deleteReducer };
