import React                  from 'react';
import { withAuthenticator  } from 'aws-amplify-react';
import Amplify                from 'aws-amplify';
import awsconfig              from './aws-exports';
import Authentication         from './components/authenticator'
import Root                   from './pages/Root';


Amplify.configure({
  ...awsconfig
});


type AppProps = {
  authData: {
    attributes: {
      sub:           string;
      email:         string;
      'custom:name': string;
    }
  }
}

const App: React.FC<AppProps> = ({ authData }) => {
    return (
       <Root authData = { authData }/>
    )
}


export default withAuthenticator(
  App,
  false,
  [
  <Authentication/>
]);

