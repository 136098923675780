import React                    from 'react';
import { makeStyles }           from '@material-ui/core/styles';

var clsx = require('classnames')

const useStyles = makeStyles(() => ({
    container: {
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
        flexFlow:        'column',
    },
    title: {
       display:         'flex',
       alignItems:      'center',
       justifyContent:  'center',
       cursor:          'default',
       height:          40,
       fontSize:        18,
       marginBottom:    15,
    },
    error: {
       display:         'flex',
       alignItems:      'center',
       justifyContent:  'center',
       cursor:          'default',
       height:          40,
       fontSize:        18,
       color:           '#E00'
    },
    input: {
        width:     400,
        fontSize:  18,
    },
    small: {
        height: 45,
    },
    big: {
        height: 350,
    },
    buttonContainer: {
        width:           '100%',
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
        flexFlow:        'row'
    },
    button: {
       width:           200,
       height:          45,
       margin:          20,
       display:         'flex',
       alignItems:      'center',
       justifyContent:  'center',
       color:           '#FFF',
       backgroundColor: '#34D',
       fontWeight:      'bold',
       cursor:          'pointer',
       '&:hover': {
          opacity: 0.9
       }
    }
}))


type EditMessagFormPropsType = {
    variant:  string;
    state:    string;
    bigModal: boolean;
    setState: ( val: string ) => void;
}

const EditMessagForm: React.FC<EditMessagFormPropsType> = ({ variant, state, bigModal, setState }) => {

    const classes = useStyles();

    const changeInputValue = (e: React.FormEvent) => {

        e.preventDefault();
    
        const target = e.target as HTMLInputElement;
    
        setState(target.value) 
    
    }


    return(
        <div className = { classes.container }>
            <textarea 
               dir         = { variant === 'notes' ? "rtl" : 'ltr' }
               className   = { clsx(classes.input, (bigModal ? classes.big : classes.small)) }
               id          = { variant }
               value       = { state }
               onChange    = { changeInputValue }
            />
        </div>
    )
}

export default EditMessagForm;