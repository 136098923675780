import { componentsStateType } from "reducer/types";

export const SET_PAGE = (payload: string) => ({
    type: 'SET_PAGE',
    payload: payload
});

export const SET_FORM = (payload: string) => ({
    type: 'SET_FORM',
    payload: payload
});

export const SET_ERROR = (payload: string) => ({
    type: 'SET_ERROR',
    payload: payload
});

export const SET_COMPONENTS_STATE = (payload: componentsStateType) => ({
    type: 'SET_COMPONENTS_STATE',
    payload: payload
});