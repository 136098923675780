import React            from 'react';
import { connect }      from 'react-redux';
import { Dispatch }     from "redux";
import { reducerType }  from 'reducer';
import { ActionTypes }  from 'actions/types';
import Row              from './Row';
import { makeStyles }   from '@material-ui/core/styles';
import { 
    deleteStateType, 
    duplicationStateType, 
    puzzleStateType 
}                       from 'types';
import { SET_PUZZLE_STATE } from 'actions/puzzleActions';
import { SET_DELETE_STATE } from 'actions/deleteActions';


var clsx  = require('classnames')


const widths: { [key: string]: number } = {
   // 'question':      500,
    'id':            30
}


const eArr = new Array(16).fill('e').map((val, ind) => { return(`${ val }${ ind + 1 }`) })
    
const variants = ['topic', 'puzzletype', 'puzzle_type_text', 'description_teacher', 'description_student', 'extra_url', 'page', 'img'].concat(eArr).concat(["id"]);

const useStyles = makeStyles(() => ({
    root: {
        position:        'fixed',
        top:             50,
        left:            0,
        width:           '100vw',
        overflowY:       'scroll',
        overflowX:       'auto',
        backgroundColor: '#FAFAFA',
        '&::-webkit-scrollbar': {
          width: '0.5em',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: `#BBB`,
          borderRadius:    10
        }
    },
    row: {
        height:          60,
        width:           '100%',
        display:         'flex',
        alignItems:      'center',
        backgroundColor: '#FFF',
    },
    fixedRow: {
       // position:        'fixed',
       // top:             50,
        left:            0,
        height:          60,
        width:           '100%',
        display:         'flex',
        alignItems:      'center',
        backgroundColor: '#FFF',
    },
    delete: {
        position:        'fixed',
        top:             50,
        height:          60,
        width:           '100%',
        display:         'flex',
        alignItems:      'center',
        backgroundColor: '#333',
        color:           '#FFF',
    },
    item: {
        height:          '100%',
        flex:            1,
        display:         'flex',
        alignItems:      'center',
        borderTop:       '1px solid #D00',
        borderLeft:      '1px solid #DDD',
        borderBottom:    '1px solid #DDD',
        flexWrap:        'wrap',
        paddingLeft:     8,

      /*   '&:hover': {
            backgroundColor: '#EEF'
        } */
    },
    title: {
        justifyContent: 'center',
        fontWeight:     'bold'
    },
    titleNote: {
        flex: 1,
    },
    button: {
        position:        'absolute',
        right:           0,
        borderTop:       '1px solid #FFF',
        minWidth:        20,
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
        color:           '#FFF',
        fontWeight:      'bold',
        cursor:          'pointer'
    }
}))


type PuzzlesTablePropsType = {
    size:           number[];
    deleteState:    deleteStateType;
    duplicateState: duplicationStateType;
    puzzles:        { [key: string]: any }[];
    setPuzzleState: ( val: puzzleStateType ) => void;
    setDeleteState: ( val: deleteStateType ) => void;
}


const PuzzlesTable: React.FC<PuzzlesTablePropsType> = ({ 
    size, 
    deleteState,
    duplicateState,
    puzzles, 
    setPuzzleState,
    setDeleteState,
}) => {

    const classes = useStyles();

    return(
        <div 
            className = { classes.root }
            style     = {{
                height: size[1] - 50
            }}
        >
                <div 
                   className = { classes.fixedRow }
                   style     = {{
                    //   width: size[0] - 10,
                   }} 
                >
                    {
                        variants.map((val) => {
                            return(
                                <div 
                                    key       = { `${ val }###c` } 
                                    className = { clsx( classes.item, classes.title ) } 
                                    style     = {{ 
                                        minWidth: val === 'id' ? 30 : !!widths[val] ? widths[val] : 200,
                                        maxWidth: val === 'id' ? 30 : undefined
                                    }}
                                >
                                   { val }
                                </div>
                            )
                        })
                    }
                </div>
                {
                   !!puzzles && puzzles.map((val, ind) => {

                       const marked = (!!val['id'] || val['id'] === 0) && deleteState.selectedItems.includes(val['id']);

                       return(
                            <div
                               key       = { `_${ ind }***` }
                               className = { classes.row }
                               style     = {{
                                 //  width:           '100%',
                                   width:           (200 * variants.length) + (variants.length),
                                   backgroundColor: marked ? '#FAA' : undefined
                               }}
                            >
                                {
                                    variants.map((variant, index) => {
                                      return(
                                        <Row
                                            key                = { `${ variant }()` }
                                            variant            = { variant }
                                            index              = { index }
                                            marked             = { marked }
                                            obj                = { val }
                                            widths             = { widths }
                                            deleteState        = { deleteState }
                                            duplicateState     = { duplicateState }
                                            setPuzzleState     = { setPuzzleState }
                                            setDeleteState     = { setDeleteState }
                                        />  
                                       )
                                    })
                                }
                                {/* <div 
                                   className = { classes.button }
                                   onClick   = { () => { handleClick( showId ? val['comments'] : val['notes']) }}
                                   style     = {{
                                      minHeight:       val['hidden'] ? 20 : 60,
                                      backgroundColor: val['hidden'] ? '#F66' : '#00F',
                                   }}
                                >
                                  { val['hidden'] ? '-' : '+' }
                                </div> */}
                            </div>
                       )
                      
                   })
                }
        </div>
    )
}

const mapStateToProps = (reducer: reducerType) => {
    return {
        puzzles:        reducer.content.puzzles,
        deleteState:    reducer.delete,
        duplicateState: reducer.duplicate,
    }
  };

  const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
    setPuzzleState: (payload: puzzleStateType) => dispatch(SET_PUZZLE_STATE(payload)),
    setDeleteState: (payload: deleteStateType) => dispatch(SET_DELETE_STATE(payload))
});

  export default connect(mapStateToProps, mapDispatchToProps)(PuzzlesTable);
