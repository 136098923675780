import React                      from 'react';
import { Auth }                   from 'aws-amplify';
import logo                       from '../../../images/logo.png';
import PasswordInput              from '../PasswordInput';
import TextField                  from '@material-ui/core/TextField';
import { makeStyles }             from '@material-ui/core/styles';
import { colors }                 from '../../../utils/colors';


const useStyles = makeStyles(theme => ({
  root: {
    display:        'flex',
    flexFlow:       'column',
    justifyContent: 'center',
    alignItems:     'center',
    width:          '90%',
    height:         '100%',
    marginLeft:     '5%',

  },
  logo: {
    width:              200,
    height:             65,
    backgroundImage:    `url(${ logo })`,
    backgroundPosition: 'center',
    backgroundSize:     'contain',
    backgroundRepeat:   'no-repeat',
  },
  form: {
    width:          '100%',
    display:        'flex',
    flexFlow:       'column',
    justifyContent: 'center',
    alignItems:     'center'
  },
  text_field: {
    height:          45,
   // fontSize:        30,
    color:           colors.darkText,
    marginTop:       10,
    marginBottom:    10,
  },
  password: {
    marginTop: 10
  },
  forgot_pwd: {
     cursor:    'pointer',
     color:     colors.darkText,
     textAlign: 'center',
     margin:    20,
     '&:hover': {
      opacity: 0.5
    } 
  },
  login: {
    marginTop:       40,
    color:           colors.darkText,
    width:           200,
    height:          40,
    borderRadius:    '0.5em',
    textAlign:       'center',
    cursor:          'pointer',
    backgroundColor: '#FFF',
    border:          `1px solid ${ colors.brightText }`,
    '&:hover': {
      opacity: 0.5
    } 
   },
   buttonText: {
    height:         '100%',
    width:          '100%',
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
    textAlign:      'center',
    fontSize:        20,
  },
  signup: {
      height:          45,
      width:           330,
      flex:            1,
      marginLeft:      5,
      marginRight:     5,
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      textAlign:       'center',
      cursor:          'pointer',
      fontWeight:      'bold',
      boxShadow:       '2px 4px 9px rgba(0,0,0,0.1)',
      backgroundColor: colors.primary,
      color:           '#FFF',
   //   borderRadius:    '0.25em',
      '&:hover': {
         opacity: 0.5
      }
  },
  loader_container: {
    width:   60,
    height:  60,
  },
  txt: {
    display:        'flex',
    justifyContent: 'center',
    alignItems:     'center',
    height:         45,
    color:          '#F00',
    cursor:         'default'
  },
  iseemath: {
    marginTop:  5,
    fontSize:   18,
    fontWeight: 'bold'
  }
}));

type SignInFormProps =  {
  size:             number[];
  email:            string;
  password:         string;
  error:            string;
  setError:         (val: string) => void;
  setEmail:         (val: string) => void;
  setPassword:      (val: string) => void;
  setDisplayForm:   (val: string) => void;
  setShowLoader:    (val: boolean) => void;
  handleCreateUser: () => void;
};

const SignInForm: React.FC<SignInFormProps> = ({
   size,
   email,
   password,
   error,
   setError,
   setShowLoader,
   setEmail,
   setPassword,
   setDisplayForm,
   handleCreateUser
  }) => {

  const classes   = useStyles();

  const textFieldStyle = React.useMemo(() => {
    return { width: size[0] > 600 ? '350px' : '90%'}
  },[size])

 
  const changeInputValue = (e: React.FormEvent) => {
    
    e.preventDefault();
    const target = e.target as HTMLInputElement;

    if (target.id === 'email')    { setEmail(target.value) }
    if (target.id === 'password') { setPassword(target.value) }

  }


  const handleSubmit = async() => {

    if (!email.length) { setError('Email cannot be empty'); return }
    if (!password.length) { setError('Password cannot be empty'); return }

    setShowLoader(true);
    
    try {
        await Auth.signIn(email, password);
        setShowLoader(false);
        window.location.reload(); 
    }
    catch(err) {

        const _error = err.message || err;

        console.error("error occured in sign_in_form handleSubmit: ",_error);
        setShowLoader(false);
        let errText = '';

        if (_error === 'Username cannot be empty') { errText = 'Email cannot be empty' }
        setError(errText.length ? errText : (_error || ''));

    }
    finally {
    }
  }
  
  return (
     <div className = { classes.root }>
             <div className = { classes.logo }/>
              <div className = { classes.txt }> { error } </div>
                <form className = { classes.form } >
                  <TextField
                     className    = { classes.text_field }
                     style        = { textFieldStyle }
                     id           = "email"
                     label        = "Email"
                     onChange     = { changeInputValue }
                  />
                 {/*  <CssTextField
                     className    = { classes.text_field }
                     style        = { textFieldStyle }
                     id           = "password"
                     label        = "Password"
                     type         = "password"
                     autoComplete = "current-password"
                     onChange     = { changeInputValue }
                  /> */}
                  <PasswordInput
                     password         = { password }
                     showHelperText   = { false }
                     textFieldStyle   = { textFieldStyle }
                     changeInputValue = { changeInputValue }
                  />
                </form>
                <div onClick = { handleSubmit } className = { classes.login }>
                    <div className = { classes.buttonText }>Log In</div>
                </div>            
               {/*  <div>
                   <div onClick = { handleForgotPassword } className = { classes.forgot_pwd }>
                     forgot password?
                   </div>
                   <div className = { classes.signup } onClick = { () => setDisplayForm('sign_up_part1') }>
                      Don't Have An Account Yet? Sign Up
                   </div>
                </div> */}
      </div>
    )
}



export default SignInForm;